import React from 'react'

type SquareIconProps = {
    icon: JSX.Element
    dark?: boolean
    round?: boolean
    color?: any
    onClick?: () => void
}
export const SquareIcon = ({ icon, dark = false, round = false, color, onClick }: SquareIconProps) => (
    <div
        className={`SquareIcon Icon__${icon.type?.render?.displayName} ${dark ? 'SquareIcon__Dark' : ''}  ${round ? 'SquareIcon__Round' : ''}`}
        style={{
            backgroundColor: color
        }}
        onClick={onClick}
    >
        <div className={`Icon__Wrapper ${dark ? 'Icon__Wrapper__Dark' : ''}`} style={{
            backgroundColor: color
        }} onClick={onClick}>
            {icon}
        </div>
    </div>
)