import { useState, useEffect } from 'react';

function getMobileDimension() {
  const { innerWidth: width } = window;

  return width > 768 ? false : true;
}

export default function useMobile() {
  const [mobileDimensions, setMobileDimensions] = useState(getMobileDimension());

  useEffect(() => {
    function handleResize() {
      setMobileDimensions(getMobileDimension());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return mobileDimensions;
}