import React, { useEffect, useRef, useState } from 'react'

import { Button, Form, FormInstance, Input, message } from 'antd'

// Store
import { useSelector } from 'react-redux'

import './PatientActivity.scss'
import { useHistory, useParams } from 'react-router-dom'
import { SvgInline } from '../../Components/SvgInline/SvgInline'
import { useMutation } from '@apollo/client'
import { DO_ACTIVITY } from './connection'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'react-qr-code'
import { oirsLogo } from '../../Assets/oirsLogo'
import { hcfbLogo } from '../../Assets/hcfbLogo'
import { useRut } from 'react-rut'
import { Rule } from 'antd/lib/form'

interface ExtraLabel {
  visit: {
    code: string
    start: string
    end: string
  }
  patient: {
    name: string
    location: string
  }
  guardian: {
    rut: string
    firstName: string
    lastName: string
  }
}

type FieldType = {
  id: number // 1
  name: string // "Tipo de Coordinación"
  description: string // "Tipo de Coordinación"
  typeValue: 'select' | 'text' | 'textarea' // "select"
  placeholder: string // "Selecciona un tipo de actividad"
  typeData?: string[] // ["Tipo de Coordinación", "Tipo de Actividad"]
}

type ActivityType = {
  id: number // 1
  name: string // "Coordinación"
  description: string // "Registro de solicitudes y gestiones realizadas por la OIRS hacia el paciente o su familiar significativo."
  icon: string // "https://storagenus.alnus.cl/61608205-1_oirs_hcfb/Icon_CoordinacionInterna.svg"
  printable: boolean // false
  sortOrder: number // 1
  Fields: FieldType[]
}

type SelectorPatientActivityProps = {
  userInfo: any
  permissions: string[]
  locations: any[]
  token: string
  activities: ActivityType[]
  currentPatient: any
}

function PatientActivity({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const {
    userInfo,
    activities,
    currentPatient: patient,
    token
  } = useSelector((state: SelectorPatientActivityProps) => state)

  const { activityId } = useParams<any>()

  const [activity, setActivity] = useState<ActivityType>()
  // const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()


  const [doActivity, e_PatientActivity] = useMutation(DO_ACTIVITY, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    onError: (error: any) => {
      message.error(error.message)
    }
  })

  const [extra, setExtra] = useState<ExtraLabel>()

  useEffect(() => {
    if (e_PatientActivity.data?.PatientDoActivity?.success) {
      if (e_PatientActivity.data?.PatientDoActivity?.extra) {
        switch (e_PatientActivity.data?.PatientDoActivity?.state) {
          // PRINT LABEL
          case 'OK_PRINT_LABEL':
            const _extra = JSON.parse(
              e_PatientActivity.data?.PatientDoActivity?.extra
            )
            setExtra(_extra)
            break
          // // Call Screen
          // case 'OK_CALL_SCREEN':
          //   // extra: "{\"callScreen\":{\"relacion\":\"Abuela\",\"name\":\"Maria Margarita Pinto Cardenas\",\"number\":\"+56 9 8501 8301\"}}"
          //   const { callScreen: _data } = JSON.parse(
          //     e_PatientActivity.data?.PatientDoActivity?.extra
          //   )
          //   setCallScreen({
          //     visible: true,
          //     ..._data
          //   })
            
          //   break
        }
      } else {
        message.success('Actividad guardada con éxito.')
        history.push(`/patient/view/${patient.id}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_PatientActivity.data])

  useEffect(() => {
    setMainLoading(e_PatientActivity.loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_PatientActivity.loading])

  useEffect(() => {
    setMainLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let _activityId = Number(activityId)
    if (activities.length > 0) {
      const _activity = activities.find(
        (activity: ActivityType) => activity.id === _activityId
      )
      if (_activity) {
        setActivity(_activity)
        console.log(_activity)
      }
    } else {
      message.error(
        'No hay actividades para mostrar, contacta al administrador.'
      )
      history.push('/patient/list')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, activityId])

  // useEffect(() => {
  //   setMainLoading(e_PatientActivity.loading)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [e_PatientActivity.loading])

  // useEffect(() => {
  //   const { data } = e_PatientActivity
  //   console.log({ data })
  //   if (data?.PatientList?.success) {
  //     setPatient(data.PatientList.Patients[0])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [e_PatientActivity.data])

  const printRef = useRef()
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => printRef.current
  })

  useEffect(() => {
    if (extra) {
      handlePrint()
      setTimeout(() => {
        history.push(`/patient/view/${patient.id}`)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra])

  return (
    <div className='App'>
      <div
        className='PaseVisita'
        // @ts-ignore
        ref={printRef}
      >
        <div className='Logos'>
          <img src={hcfbLogo} width={48} alt='logo' />

          <img src={oirsLogo} width={48} alt='logo' />
        </div>

        <div className='Center'>
          <div className='Left'>
            <div className='QR'>
              {extra?.visit.code && (
                <QRCode size={100} value={extra?.visit.code} />
              )}
              <p>{extra?.visit.code}</p>
            </div>
            <div className='Patient'>
              Visitante de:
              <br />
              {extra?.patient?.name}
            </div>
          </div>
          <div className='Right'>
            <div className='RUT'>{extra?.guardian.rut}</div>
            <div className='Name'>
              {extra?.guardian.firstName}
              <br />
              {extra?.guardian.lastName}
            </div>
            <div className='Table'>
              <div className='Location'>{extra?.patient.location}</div>
              <div className='Date'>
                <div className='Start'>{extra?.visit.start}</div>
                <div className='End'>{extra?.visit.end}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='PatientActivity'>
        <div className='Wrapper'>
          <div className='PatientActivity__Header'>
            <div className='PatientActivity__Header__Icon'>
              {activity?.icon && <SvgInline url={activity?.icon} />}
            </div>
            <div className='PatientActivity__Header__Title'>
              <h1>{activity?.name}</h1>
              <h2>
                Registrando en ficha de paciente{' '}
                <strong>
                  {patient?.Person?.firstName} {patient?.Person?.lastName}{' '}
                  {patient?.Person?.surName}
                </strong>
              </h2>
            </div>
          </div>
          <div className='PatientActivity__Body'>
            <div className='PatientActivity__Body__Content'>
              <p>{activity?.description}</p>
              {activity?.Fields?.length && (
                <p>
                  Completa los campos requeridos y haz clic en{' '}
                  <strong>Continuar</strong> para registrar correctamente la
                  actividad "{activity?.name}" en la ficha de{' '}
                  {patient?.Person?.firstName}.
                </p>
              )}
            </div>
            <Form
              form={form}
              className='PatientActivity__Body__Form'
              onFinish={(values) => {
                const _values = {
                  patientId: patient?.id,
                  activityId: Number(activityId),
                  authorizingId: userInfo?.Profile?.id,
                  clientId: 1,
                  Answers: [
                    ...Object.entries(values.Fields).map(
                      ([fieldId, data]: any) => {
                        return {
                          patientId: patient?.id,
                          activityId: Number(activityId),
                          activityFieldId: Number(fieldId.split('_')[1]),
                          answer: data.value,
                          reporterUserId: userInfo?.Profile?.id,
                          clientId: 1
                        }
                      }
                    )
                  ]
                }
                doActivity({
                  variables: {
                    PatientDoActivityInput: _values
                  }
                })
              }}
            >
              {activity?.Fields?.map((field: FieldType) => {
                if (field.typeValue === 'select') {
                  return (
                    <Form.Item
                      key={field.id}
                      name={['Fields', `fieldId_${field.id}`, 'value']}
                      label={field.description}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor selecciona una opción'
                        }
                      ]}
                    >
                      <select
                        className='PatientActivity__Body__Form__Select'
                        name={field.name}
                        defaultValue={field.placeholder}
                      >
                        <option hidden value={field.placeholder} disabled>
                          {field.placeholder}
                        </option>
                        {field.typeData?.map(
                          (option: string, index: number) => (
                            <option
                              key={`Field${field.id}_Option${index}`}
                              value={option}
                            >
                              {option}
                            </option>
                          )
                        )}
                      </select>
                    </Form.Item>
                  )
                } else if (field.typeValue === 'textarea') {
                  return (
                    <Form.Item
                      key={field.id}
                      name={['Fields', `fieldId_${field.id}`, 'value']}
                      label={field.description}
                    >
                      <Input.TextArea
                        className='PatientActivity__Body__Form__Textarea'
                        name={field.name}
                        size='large'
                        rows={4}
                        placeholder={field.placeholder}
                      />
                    </Form.Item>
                  )
                } else {
                  return <ActivityInput {...field} form={form} />
                }
              })}
              {/* @ts-ignore */}
              {console.log(form.getFieldsError())}
              <Form.Item
                shouldUpdate
                className='PatientActivity__Body__Form__Button'
              >
                {() => (
                  <Button
                    type='primary'
                    htmlType='submit'
                    block
                    size='large'
                    loading={e_PatientActivity.loading}
                    disabled={
                      // !form.isFieldsTouched(true) ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                  >
                    Continuar
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

type ActivityInputProps = {
  id: number
  description: string
  name: string
  placeholder: string
  form: FormInstance<any>
  typeData?: string[]
}

const ActivityInput = ({
  id,
  description,
  name,
  placeholder,
  typeData,
  form
}: ActivityInputProps) => {
  const [{ formattedValue, isValid }, setRut] = useRut()

  const isRUT = name === 'RUT'
  const optional = typeData?.some((option: string) => option === 'optional') || isRUT
  const numeric = typeData?.some((option: string) => option === 'numeric')
  const length =
    typeData?.find((option: string) => option.indexOf('length') === 0)?.split(':')[1] || -1

  const rules: Rule[] = [
    {
      required: !optional,
      message: `Error. ${placeholder}.`
    },
    {
      validator: (rule: any, value: any, callback: any) => {
        if (isRUT && value && !isValid) {
          callback('Por favor ingresa un RUT válido')
        }
        callback()
      }
    }
  ]

  if (numeric) {
    rules.push({
      pattern: new RegExp('[0-9]{1,}'),
      message: `Error. ${placeholder}.`
    })
  }

  if (length > 0) {
    const min = Number(length)
    rules.push({
      min,
      max: min,
      message: `Error. Debes ingresar ${min} ${numeric ? 'digitos' : 'letras'}.`
    })
  }

  console.log({
    numeric,
    length,
    rules
  })
  return (
    <Form.Item
      key={id}
      name={['Fields', `fieldId_${id}`, 'value']}
      label={description}
      rules={rules}
    >
      <Input
        className='PatientActivity__Body__Form__Input'
        name={name}
        value={formattedValue}
        pattern={numeric ? '[0-9]{9}' : undefined}
        onChange={(e) => {
          if (isRUT) {
            setRut(e.target.value)
          }
        }}
        onBlur={(e) => {
          const currValue = form.getFieldsValue()
          if (isRUT) {
            currValue.Fields[`fieldId_${id}`] = { value: formattedValue }

            form.setFieldsValue(currValue)
          }
        }}
        size='large'
        placeholder={placeholder}
      />
    </Form.Item>
  )
}

export default PatientActivity
