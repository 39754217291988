import { gql } from '@apollo/client'

export const SCAN_QR = gql`
  query PatientScanQR($PatientScanQRInput: PatientScanQRInput!) {
    PatientScanQR(input: $PatientScanQRInput) {
      success
      state
    }
  }
`

export const USER_ME = gql`
  query UserMe {
    UserMe {
      success
      state
      refreshToken
      permissions
      Extras {
        id
        name
        value
      }
      GroupInfo {
        id
        internalName
        name
      }
      Profile {
        id
        firstName
        lastName
        rut
        mail
        avatar
      }
      activityModule {
        id
        name
        description
        icon
        printable
        sortOrder
        Fields {
          id
          name
          description
          typeValue
          typeData
          placeholder
        }
      }
      quickAccess
      releaseEvents {
        id
        name
        descrpition
        icon
      }
    }
  }
`
