// import axios from 'axios'

import { LOGIN_USER, UPDATE_PROFILE } from '../../Constants'

interface LoginResponseType extends ProfileResponseBaseType {
  token: string
  text: string
}

interface UpdateResponseType extends ProfileResponseBaseType {
  refreshToken: string
  activityModule: any[]
  quickAccess: string[]
  releaseEvents: any[]
}

type ProfileResponseBaseType = {
  success: boolean
  state: string
  Extras: {
    id: string
    name: string
    value: string
  }[]
  GroupInfo: {
    id: string
    internalName: string
    name: string
  }
  Profile: {
    id: string
    firstName: string
    lastName: string
    rut: number
    mail: string
    avatar?: string
  }
  permissions: string[]
  __typename: string
}

export function addData(data: any) {
  return async (dispatch: any) => {
    console.log('addData', data)
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        data
      }
    })
  }
}

export function updateProfile(response: UpdateResponseType) {
  return async (dispatch: any) => {
    const {
      success,
      state,
      refreshToken,
      permissions,
      __typename,
      quickAccess,
      activityModule: activities,
      releaseEvents,
      ...userInfo
    } = response

    console.log(activities)

    if (
      success &&
      state === 'REFRESH_TOKEN_SUCCESS' &&
      refreshToken.length > 0
    ) {
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          token: refreshToken,
          userInfo,
          permissions,
          activities,
          quickAccess,
          releaseEvents
        }
      })
      return userInfo
    } else {
      // ¿que se hace cuando el refresh viene malo?
      console.log('Refresh malo')
    }
  }
}

export function attemptLogin(response: LoginResponseType) {
  return async (dispatch: any) => {
    const {
      success,
      state,
      token,
      text,
      permissions,
      __typename,
      ...userInfo
    } = response

    if (success && state === 'OK_LOGIN' && token.length > 0) {
      dispatch({
        type: LOGIN_USER,
        payload: {
          token,
          userInfo,
          permissions,
          isLogged: true
        }
      })
      return userInfo
    } else {
      dispatch({
        type: LOGIN_USER,
        payload: {
          token: null,
          userInfo: null,
          isLogged: false
        }
      })
      return 'El usuario y/o clave ingresado, no son válidos.'
    }
  }
}

export function logout(payload: any) {
  return { type: 'LOGOUT', payload }
}

export function setNavigation(payload: boolean) {
  return { type: 'BLOCK_NAVIGATION', payload }
}

export function setModal(payload: any) {
  return { type: 'MODAL_CREATE_TYPE', payload }
}

export function setCurrentPatient(payload: any) {
  return { type: 'UPDATE_PATIENT', payload }
}

export function setDrawerActivities(payload: boolean) {
  return { type: 'SET_DRAWER_ACTIVITIES', payload }
}