import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useMobile } from '../Utils'

import axios from 'axios'

import { connect } from 'react-redux'
import AppFrame from '../Components/AppFrame'

const mapStateToProps = ({ token, isLogged }: any) => {
    return { token, isLogged }
}

const PrivateRoute = ({ component: Component, isLogged, ...rest}: any) => {
    const isMobile = useMobile()

    useEffect(() => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        return () => {
            source.cancel()
        }
    }, [])

    return (
        <Route {...rest} render={(props: any) => {
            const stateProps = {
                isLogged,
                isMobile
            }
            return (isLogged ?
                <AppFrame {...stateProps}>
                    <Component {...props} {...stateProps} />
                </AppFrame>
            : <Redirect to="/" />
        )}} />
    )
}

export default connect(mapStateToProps)(PrivateRoute)