import { gql } from "@apollo/client";

export const USER_LOGIN = gql`
mutation UserLogin($UserLoginInput: UserLoginInput!) {
  UserLogin(input: $UserLoginInput) {
    token
    success
    state
    text
    Extras {
      id
      name
      value
    }
    GroupInfo {
      id
      internalName
      name
    }
    Profile{
      id
      firstName
      lastName
      rut
      mail
      avatar
    }
    permissions
  }
}
`