export const serverUrl = 'https://app.bodegaenlinea.cl/api/'
const forceProd = false
export const API_URL =
  forceProd || process.env.NODE_ENV === 'production'
    ? 'https://api.oirscontigo.cl/graphql'
    : 'http://192.168.1.7:8000/graphql'
export const SERVER_URL =
  forceProd || process.env.NODE_ENV === 'production'
    ? 'https://api.oirscontigo.cl/graphql'
    : 'http://192.168.1.7:8000'
export const UPLOAD_URL =
  forceProd || process.env.NODE_ENV === 'production'
    ? 'https://api.oirscontigo.cl/graphql'
    : 'http://192.168.1.7:8000/mtdupload'
