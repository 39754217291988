import React, { useEffect, useState } from 'react'

import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import type { FilterValue } from 'antd/lib/table/interface'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip
} from 'antd'

import {
  RetweetOutlined,
  UserOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'

// Store
import { useSelector } from 'react-redux'

import './PatientArchive.scss'
import { useQuery } from '@apollo/client'
import { PATIENT_ARCHIVE } from './connection'
import { Link, useLocation } from 'react-router-dom'
import { format, isValid } from 'date-fns'

import { useForm } from 'antd/es/form/Form'
import { useRut } from 'react-rut'
import { useTranslation } from 'react-i18next'

type SelectorPatientArchiveProps = {
  userInfo: any
  permissions: string[]
  locations: any[]
  token: string
}

type CardType = {
  id: number
  statusId?: number
  statusImage?: string
  Person: {
    firstName: string
    lastName: string
    surName: string
    socialName: string
  }
  PatientLocation: {
    ingressDate: Date
    releaseDate?: Date
    DefReleaseEvent?: {
      id: number
      name: string
    }
    Location: {
      id: number
      code: string
      name: string
    }
  }
  PatientGuardian: {
    Guardian: {
      firstName: string
      lastName: string
      surName: string
      socialName: string
    }
  }
}

function PatientArchive({
  isMobile,
  exposeSubMenu,
  setMainLoading,
  setRightIcon
}: any) {
  const { token } = useSelector((state: SelectorPatientArchiveProps) => state)
  const { t } = useTranslation()
  const { state } = useLocation<any>()

  const [search, setSearch] = useState<string>(
    state?.search.replaceAll('.', '').split('-')[0] ?? ''
  )
  const [locationId, setLocationId] = useState<number>()
  const [statusId, setStatusId] = useState<number>()
  const [patientId] = useState<number>()
  const [locations, setLocations] = useState<any[]>([])

  const [liveLocationId, setLiveLocationId] = useState<any>()
  const [liveStatusId, setLiveStatusId] = useState<any>()

  const [cards, setCards] = useState<CardType[]>([])

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 20
  })

  const [form] = useForm()
  const [{ formattedValue }, setRut] = useRut()

  const e_patientList = useQuery(PATIENT_ARCHIVE, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    variables: {
      PatientListInput: {
        search,
        locationId,
        patientId,
        statusId,
        archive: true,
        limit: pagination.pageSize,
        page: pagination.current
      }
    }
  })

  const _acompanamientos = [
    'Acompañamiento Fin de Vida',
    'Acompañamiento Pediatrico',
    'Dependencia Severa',
    'Acompañamiento al Parto',
    'Acompañamiento Adulto Mayor'
  ]

  const columns: ColumnsType<CardType> = [
    {
      title: 'Id. Único',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => <Tooltip title={`${record.PatientLocation.Location.code}`}><InfoCircleOutlined /> {text}</Tooltip>,
    },
    {
      title: 'Nombre',
      dataIndex: ['Person', 'firstName'],
      key: 'Person.firstName'
    },
    {
      title: 'Apellido Pat.',
      dataIndex: ['Person', 'lastName'],
      key: 'Person.lastName'
    },
    {
      title: 'Apellido Mat.',
      dataIndex: ['Person', 'surName'],
      key: 'Person.surName'
    },
    {
      title: 'Ingreso',
      dataIndex: ['PatientLocation', 'ingressDate'],
      key: 'PatientLocation.ingressDate',
      render: (text: string) =>
        isValid(new Date(text)) ? format(new Date(text), 'dd/MM/yyyy') : ''
    },
    {
      title: 'Egreso',
      dataIndex: ['PatientLocation', 'releaseDate'],
      key: 'PatientLocation.releaseDate',
      render: (text: string) =>
        isValid(new Date(text))
          ? format(new Date(text), 'dd/MM/yyyy hh:mm')
          : ''
    },
    {
      title: 'Tutor',
      key: 'tutor',
      dataIndex: 'tutor',
      align: 'center',
      render: (_, record) => (
        <>
          {!!record.PatientGuardian?.Guardian?.firstName ? (
            <Tooltip
              title={`${record.PatientGuardian?.Guardian?.firstName} ${record.PatientGuardian?.Guardian?.lastName}`}
            >
              <CheckCircleOutlined style={{ color: '#4caf50' }} />
            </Tooltip>
          ) : (
            <CloseCircleOutlined style={{ color: '#e91e63' }} />
          )}
        </>
      )
    },
    {
      title: 'Acompañamiento',
      key: 'acompanamiento',
      dataIndex: 'acompanamiento',
      align: 'center',
      render: (_, record) => (
        <>
          {record.statusId !== null && record.statusId !== undefined ? (
            <Tooltip
              title={_acompanamientos[record.statusId]}
            >
              <CheckCircleOutlined style={{ color: '#4caf50' }} />
            </Tooltip>
          ) : (
            <CloseCircleOutlined style={{ color: '#e91e63' }} />
          )}
        </>
      )
    },
    {
      title: 'Acciones',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Link to={`/patient/archive/view/${record.id}/1`}>Ver ficha</Link>
          {/* <Link style={{ color: '#e91e63' }} to={`/patient/view/${record.id}`}>
            Recuperar
          </Link> */}
        </Space>
      )
    }
  ]

  /** Al ejecutarse la vista */
  useEffect(() => {
    setMainLoading(false)
    setRightIcon({
      icon: <RetweetOutlined style={{ fontSize: 28 }} />,
      onClick: () => {
        setMainLoading(true)
        setTimeout(() => {
          e_patientList.refetch()
          setMainLoading(false)
        }, 1500)
      }
    })
    return () => {
      setRightIcon({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Cargando API */
  // useEffect(() => {
  //   setMainLoading(e_patientList.loading)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [e_patientList.loading])

  /** Setear información desde la API */
  useEffect(() => {
    const { data } = e_patientList
    if (data?.PatientList?.success) {
      const _cards = data.PatientList.Patients
      setCards(_cards)
      setPagination({
        ...pagination,
        total: data.PatientList.total
      })
      setLocations(data.PatientList.finalLocations)
      // Only first occurrence of each patient
      // const _cardsUnique = _cards.reduce((acc: any, cur: any) => {
      //   if (!acc.find((x: any) => x.Person?.id === cur.Person?.id)) {
      //     acc.push(cur)
      //   }
      //   return acc
      // }, [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_patientList.data])

  /** Formatear RUT */
  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      patient: {
        ...form.getFieldValue('patient'),
        rut: formattedValue
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedValue])

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any // SorterResult<CardType>,
  ) => {
    setPagination(newPagination)
    // fetchData({
    //   sortField: sorter.field as string,
    //   sortOrder: sorter.order as string,
    //   pagination: newPagination,
    //   ...filters,
    // });
  }

  /**
   * Vista para cuando no hay resultados de la búsqueda.
   */
  // if (!e_patientList.loading && ((cards.length + others.length) === 0)) {
  //   return <div className='App'>
  //     <div className='PatientArchive'>
  //       <div className='Wrapper'>
  //         <Search onSearch={(value) => {
  //           setSearch(value)
  //         }} style={{
  //           marginTop: 16
  //         }} placeholder="Ingresa un texto para buscar..." enterButton="Buscar" size="large" loading={e_patientList.loading} />
  //         <Empty style={{
  //           marginTop: 24
  //         }} />
  //       </div>
  //     </div>
  //   </div>
  // }

  /**
   * Render general.
   */
  return (
    <div className='App'>
      <div className='PatientArchive'>
        <div className='Wrapper'>
          <Row gutter={[16, 16]}>
            <Col span={4}>
              {/* <Search onSearch={(value) => {
                setSearch(value)
              }} style={{
                marginTop: 16
              }} placeholder="Ingresa un texto para buscar..." enterButton="Buscar" size="large" loading={e_patientList.loading} /> */}
              <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24, style: { marginBottom: 18 } }}
                initialValues={{ remember: true }}
                layout='vertical'
                onSubmitCapture={(e) => {
                  setRut(form.getFieldValue('patient').rut)                  
                }}
                onFinish={(values) => {
                  setSearch(values.patient.rut)
                  setLocationId(values.patient.locationId)
                  setStatusId(values.patient.statusId)
                  console.log({ values })
                }}
                // onFinishFailed={onFinishFailed}
                autoComplete='off'
              >
                <Form.Item
                  style={{
                    marginBottom: 0,
                    marginTop: 16
                  }}
                >
                  <Button
                    block
                    size='large'
                    type='primary'
                    htmlType='submit'
                    disabled={e_patientList.loading || 
                      (formattedValue === search &&
                      liveLocationId === locationId &&
                      liveStatusId === statusId)
                    }
                  >
                    Buscar
                  </Button>
                </Form.Item>

                <Form.Item
                  label='RUT del Paciente'
                  name={['patient', 'rut']}
                  help='El RUT del paciente sin puntos ni guiones, éstos serán completados automaticamente.'
                >
                  <Input
                    role='presentation'
                    autoComplete='off'
                    allowClear
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    placeholder={t('RUT del Paciente...')}
                    onChange={(e) => {
                      if (e.target.value.trim().length === 0 && formattedValue.length > 0) {
                        setRut('')
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        // @ts-ignore
                        e.target.blur()
                        // @ts-ignore
                        setRut(e.target.value)
                        setTimeout(() => {
                          form.submit()
                        }, 100)
                      }
                    }}
                    onBlur={(e) => {
                      setRut(e.target.value)
                    }}
                    autoFocus={true}
                  />
                </Form.Item>

                <Form.Item
                  label='Tipo de Acompañamiento'
                  name={['patient', 'statusId']}
                  help='Puedes filtrar por el acompañamiento del paciente al momento de la liberación de cama.'
                >
                  <Select
                    placeholder={'Selecciona un estado del paciente...'}
                    style={{ width: '100%' }}
                    allowClear
                    onClear={() => {
                      setLiveStatusId(undefined)
                    }}
                    onSelect={(value) => {
                      setLiveStatusId(value)
                    }}
                  >
                    {_acompanamientos.map((acompanamiento, index) => <Select.Option value={index}>{acompanamiento}</Select.Option>)}
                  </Select>
                </Form.Item>

                {locations.length > 0 && <Form.Item
                  label='Cama'
                  name={['patient', 'locationId']}
                  help='Puedes filtrar por la última ubicación del paciente.'
                >
                  <Select
                    placeholder={'Selecciona una cama...'}
                    style={{ width: '100%' }}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    onClear={() => {
                      setLiveLocationId(undefined)
                    }}
                    onSelect={(value) => {
                      setLiveLocationId(value)
                    }}
                  >
                    {locations.map(({ id, code }) => <Select.Option value={id}>{code}</Select.Option>)}
                  </Select>
                </Form.Item>}
              </Form>
            </Col>
            <Col span={20}>
              <Table
                columns={columns}
                dataSource={cards}
                size='small'
                style={{
                  marginTop: 16
                }}
                pagination={pagination}
                loading={e_patientList.loading}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default PatientArchive
