export default function stringToStyleArray(string: string, split = true) {
  let styles: any[] = []
  const dom = (new DOMParser()).parseFromString(string, "text/html")
  dom.querySelectorAll('[style]').forEach((el) => {
    if(split) {
      // @ts-ignore
      styles = [...styles, ...el.getAttribute("style").split('')]
    }
    else {
      styles.push(el.getAttribute("style"))
    }
  })

// console.log(styles.reduce((acc, style) => {
//     const [key, value] = style.split(':')
//     return {
//       ...acc,
//       [key.trim()]: value.trim()
//     }
//    }, []))
  return styles.reduce((acc, style) => {
    const [key, value] = style.split(':')
    acc.push({
      [key.trim()]: value.trim()
    })
    return acc
   }, [])
}
