import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  Select,
  Steps,
  Switch,
  Badge
} from 'antd'

import { ExclamationCircleOutlined, QrcodeOutlined, FontSizeOutlined, QuestionOutlined, InfoCircleTwoTone } from '@ant-design/icons'

// Store
import { useDispatch, useSelector } from 'react-redux'

import './RegisterPatient.scss'

import {
  GET_IDENTITY,
  GET_LOCATIONS,
  PATIENT_ADD,
  PATIENT_GUARDIAN_ADD,
  UPDATE_PERSON_CONTACT
} from './connection'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { UPDATE_PROFILE } from '../../Constants'

import QrReader from 'react-qr-reader'
import { useRut, isValid as validRUT, format as formatRUT } from 'react-rut'
// import { TreeSelect } from 'antd-mobile'
import { setNavigation } from '../../Store/Actions'
import { useHistory, useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import BraftEditor from 'braft-editor'

const { Step } = Steps
const { Option } = Select

type SelectorRegisterPatientProps = {
  userInfo: any
  permissions: string[]
  token: string
  locations: any[]
}

function RegisterPatient({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const { token, locations } = useSelector(
    (state: SelectorRegisterPatientProps) => state
  )
  const [form] = Form.useForm()
  const [{ formattedValue, isValid }, setRut] = useRut()

  const [
    { formattedValue: formattedValueGuardian, isValid: isValidGuardian },
    setRutGuardian
  ] = useRut()

  const dispatch = useDispatch()
  const [matchRut, setMatchRut] = useState(false)

  const [step, setStep] = useState<number>(0)
  const [modalQR, setModalQR] = useState(false)
  const [validating, setValidate] = useState(false)
  const [started, setStarted] = useState(false)

  const [startedGuardian, setStartedGuardian] = useState(false)
  const [matchRutGuardian, setMatchRutGuardian] = useState(false)

  const [PatientInstance, setPatient] = useState<any>()
  const [GuardianInstance, setGuardian] = useState<any>()
  const [guardianId, setGuardianId] = useState<number>(0)

  const [nnRUT, setNNRut] = useState(false)

  const [expressRegister, setExpressRegister] = useState(false)
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState('')
  )

  const history = useHistory()
  const location = useLocation()
  const _refOBS = useRef(null)

  const validateQR = (_url: string) => {
    if (!validating) {
      setValidate(true)
      // 'https://portal.sidiv.registrocivil.cl/docstatus?RUN=18731198-5&type=CEDULA&serial=517894085&mrz=517894085194061772406178'
      try {
        const url = new URL(_url)
        const runRUT = url.searchParams.get('RUN')!

        const _setter = new Map([
          [0, setRut],
          [1, setRutGuardian]
        ])

        // @ts-ignore
        const set = _setter.get(step)!
        console.log(`Setter de ${step}: `, set, runRUT)
        set(runRUT)

        // @ts-ignore
        _next(runRUT)
        return runRUT
      } catch (err) {
        message.error(
          'El código QR escaneado no pertenece a una cédula de identidad, intente nuevamente.'
        )
        setValidate(false)
        return false
      }
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.locationId) {
      const currVal = form.getFieldsValue()
      currVal.patient = {
        ...currVal.patient,
        // @ts-ignore
        inconexLocId: location?.state?.locationId
      }
      form.setFieldsValue(currVal)
      setExpressRegister(true)
      setStarted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    setValidate(false)
    identityEvents.called = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const [patientAdd, patientAddEvents] = useMutation(PATIENT_ADD, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    onError: (err) => {
      message.error(err.message, 10)
      dispatch(setNavigation(false))
      setTimeout(() => {
        history.goBack()
      }, 300)
    }
  })
  const [guardianAdd, guardianAddEvents] = useMutation(PATIENT_GUARDIAN_ADD, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  const [getIdentity, identityEvents] = useLazyQuery(GET_IDENTITY, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })
  const [updatePersonContact, updatePersonContactEvents] = useMutation(
    UPDATE_PERSON_CONTACT,
    {
      context: {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    }
  )

  const { data, loading } = useQuery(GET_LOCATIONS, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      GetLocationTreeInput: {
        final: true
      }
    },
  })

  useEffect(() => {
    if (data?.HandlersGetLocationTree?.success) {
      console.log(data.HandlersGetLocationTree.location)
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          locations: data.HandlersGetLocationTree.location
        }
      })
    }
  }, [data, dispatch])

  useEffect(() => {
    const currVal = form.getFieldsValue()
    currVal.patient = {
      ...currVal.patient,
      RUT: formattedValue
    }
    form.setFieldsValue(currVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedValue, isValid])
  useEffect(() => {
    const currVal = form.getFieldsValue()
    currVal.guardian = {
      ...currVal.guardian,
      RUT: formattedValueGuardian
    }
    form.setFieldsValue(currVal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedValueGuardian, isValidGuardian])

  useEffect(() => {
    setMainLoading(updatePersonContactEvents.loading)

    if (updatePersonContactEvents?.data?.UpdatePersonContact?.success) {
      // Enable navigation
      dispatch(setNavigation(false))
      setTimeout(() => {
        // Notify sucess
        message.success(
          `Paciente registrado con éxito, código único: ${PatientInstance?.id}`
        )
        // View patient details
        history.push(`/patient/view/${PatientInstance?.id}`)
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updatePersonContactEvents.data,
    updatePersonContactEvents.loading,
    updatePersonContactEvents.error
  ])
  useEffect(() => {
    console.log({ patientAddEvents })
    if (patientAddEvents?.data?.PatientAdd?.success) {
      console.log('Patient added', patientAddEvents.data.PatientAdd.Patient)
      if (expressRegister) {
        dispatch(setNavigation(false))
        setTimeout(() => {
          // Redirect to CENSO
          // @ts-ignore
          history.push(location?.state?.returningUrl, { expressRegister: true })
          // history.push('/location/ocupation/1', { expressRegister: true })
        }, 300)
      } else {
        setStep((v) => {
          return v + 1
        })
        setPatient(patientAddEvents.data.PatientAdd.Patient)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientAddEvents.loading, patientAddEvents.error, patientAddEvents.data])
  useEffect(() => {
    console.log({ guardianAddEvents })
    if (guardianAddEvents?.data?.PatientGuardianAdd?.success) {
      console.log(
        'Guardian added',
        guardianAddEvents,
        guardianAddEvents.data.PatientGuardianAdd.PatientGuardian.Guardian
      )
      setStep((v) => {
        return v + 1
      })
      setGuardian(
        guardianAddEvents.data.PatientGuardianAdd.PatientGuardian.Guardian
      )
      setGuardianId(guardianAddEvents.data.PatientGuardianAdd.PatientGuardian.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    guardianAddEvents.loading,
    guardianAddEvents.error,
    guardianAddEvents.data
  ])
  useEffect(() => {
    if (identityEvents.data?.HandlersGetIdentity?.success) {
      // Patient Information
      if (step === 0) {
        updateForm(setMatchRut, identityEvents.data, 'patient')
        console.log(_refOBS.current)
        setTimeout(() => {
          // @ts-ignore
          _refOBS.current?.focus()
          document.getElementById('patient_obs')?.focus()
        }, 300)
      }

      // Guardian Information
      if (step === 1) {
        updateForm(setMatchRutGuardian, identityEvents.data, 'guardian')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityEvents.data, identityEvents.loading, identityEvents.error])

  const updateForm = (
    matchRut: (d: any) => void,
    data: any,
    node: 'patient' | 'guardian'
  ) => {
    matchRut(!!data.HandlersGetIdentity?.names)
    const currVal = form.getFieldsValue()

    const otherFields: any =
      data.HandlersGetIdentity.birthDay !== null
        ? {
            birthday: data.HandlersGetIdentity.birthDay
          }
        : {}

    if (data.HandlersGetIdentity.socialName !== null) {
      otherFields.socialName = data.HandlersGetIdentity.socialName
    }

    if (data.HandlersGetIdentity.sexId !== null) {
      otherFields.sexId = `${data.HandlersGetIdentity.sexId}`
    }

    currVal[node] = {
      ...currVal[node],
      names: data.HandlersGetIdentity.names,
      lastName: data.HandlersGetIdentity.lastName,
      surName: data.HandlersGetIdentity.surName,
      ...otherFields
    }
    form.setFieldsValue(currVal)
  }

  useEffect(() => {
    dispatch(setNavigation(true))
    setMainLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _next = (runRUT?: string) => {
    console.log('_next', runRUT, step)

    if (step === 0) {
      let _localRut = runRUT || formattedValue
      let _rawValue = _localRut.replace(/[^0-9]/g, '')
      let _isValid = runRUT ? true : isValid
      if (_isValid && _rawValue.length > 6 && !identityEvents.called) {
        getIdentity({
          variables: {
            GetIdentityInput: {
              rut: Number(_localRut.split('-')[0].replaceAll('.', ''))
            }
          }
        })
      } else {
        form.submit()
      }
    }

    if (step === 1) {
      let _localRut =
        runRUT || formattedValueGuardian || form.getFieldValue('guardian.RUT')
      let _rawValue = _localRut.replace(/[^0-9]/g, '')
      let _isValid = runRUT ? true : isValidGuardian
      const condition =
        _isValid && _rawValue.length > 6 && !identityEvents.called

      console.log({ condition, _isValid, _rawValue, _localRut })
      if (condition) {
        getIdentity({
          variables: {
            GetIdentityInput: {
              rut: Number(_localRut.split('-')[0].replaceAll('.', ''))
            }
          }
        })
      } else {
        form.submit()
      }
    }

    if (step === 2) {
      form.submit()
    }
  }

  return (
    <div className='App'>
      <div className='RegisterPatient'>
        <div className='Wrapper'>
          <Steps progressDot current={step}>
            <Step title='Paciente' />
            <Step title='Tutor' />
            <Step title='Contacto' />
          </Steps>

          <Modal
            title={'Ingreso con QR de Cédula de Identidad'}
            visible={modalQR}
            centered
            footer={null}
            className='TitledModal'
            onCancel={() => setModalQR(false)}
          >
            {modalQR && (
              <QrReader
                delay={100}
                resolution={600}
                showViewFinder={false}
                facingMode='environment'
                key={`QR${modalQR}`}
                onError={(err) => {
                  console.error(err)
                }}
                onScan={(data) => {
                  if (data && !validating) {
                    setTimeout(() => {
                      if (step === 0) {
                        setStarted(true)
                      }
                      if (step === 1) {
                        setStartedGuardian(true)
                      }
                      setModalQR(false)
                      validateQR(data)
                    }, 100 * Math.round(Math.random() * 10))
                  }
                }}
                style={{ width: '100%' }}
              />
            )}
          </Modal>

          <Form
            form={form}
            size='large'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout='vertical'
            initialValues={{
              patient: {
                sexId: 3,
                ingressDate: format(new Date(), "yyyy-MM-dd'T'HH:mm")
              }
            }}
            onFinish={(values) => {
              console.log(values, step, started, identityEvents.called)

              // Save info patient
              if (step === 0) {
                if (!identityEvents.called) {
                  setRut(values.patient.RUT)
                  if (validRUT(values.patient.RUT)) {
                    _next(formatRUT(values.patient.RUT))
                  } else {
                    message.error('Ingresa un RUT válido.')
                  }
                } else {
                  const PatientAddInput = { ...values.patient }

                  if (PatientAddInput?.sexId) {
                    PatientAddInput.sexId = Number(PatientAddInput.sexId)
                  }
                  if (PatientAddInput?.obs) {
                    PatientAddInput.obs = editorState.toHTML()
                  }

                  patientAdd({
                    variables: {
                      PatientAddInput
                    }
                  })
                }
              }

              // Save info guardian
              if (step === 1) {
                if (isValidGuardian && !identityEvents.called) {
                  _next()
                } else {
                  if (values?.guardian?.sexId) {
                    values.guardian.sexId = Number(values.guardian.sexId)
                  }
                  guardianAdd({
                    variables: {
                      PatientGuardianAddInput: {
                        patientId: PatientInstance.id,
                        ...values.guardian
                      }
                    }
                  })
                }
              }

              // Contact information
              if (step === 2) {
                console.log({
                  GuardianInstance,
                  ...values.contact
                })
                updatePersonContact({
                  variables: {
                    UpdatePersonContactInput: {
                      personId: guardianId,
                      isWhatsApp: values.contact.isWhatsApp,
                      mail: values.contact.mail,
                      phone: values.contact.phoneId
                    }
                  }
                })
              }
            }}
          >
            {/* Patient Form */}
            {step === 0 && (
              <>
                <Card
                  loading={
                    loading ||
                    identityEvents.loading ||
                    patientAddEvents.loading
                  }
                  title='Información del Paciente'
                >
                  <p>
                    Completa la información del paciente para almacenar el
                    ingreso al <strong>Hospital Clínico Felix Bulnes</strong>.
                  </p>
                  {!started && <><small>
                    <InfoCircleTwoTone twoToneColor={'orange'} /> Opción recomendada según el dispositivo utilizado.
                  </small><br /><br /></>}
                  {!started && (
                    <div className="horizontalButtons">
                      <Button
                        onClick={() => {
                          setModalQR(true)
                        }}
                        block
                        // type='primary'
                        size='large'
                        icon={<Badge color={isMobile ? 'orange' : undefined} dot count={isMobile ? 1 : 0}><QrcodeOutlined /></Badge>}
                      >
                        Ingreso con Cédula de Identidad
                      </Button>
                      <Button
                        onClick={() => {
                          setStarted(true)
                        }}
                        block
                        size='large'
                        icon={<Badge color={!isMobile ? 'orange' : undefined} dot count={!isMobile ? 1 : 0}><FontSizeOutlined /></Badge>}
                      >
                        Ingreso manual del RUT
                      </Button>
                    </div>
                  )}

                  {started && (
                    <>
                      {!nnRUT && <Form.Item
                        name={['patient', 'RUT']}
                        label='RUT'
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Ingrese el RUT del paciente'
                          }
                        ]}
                      >
                        <Input
                          onBlur={(e) => {
                            setRut(e.target.value)
                          }}
                          autoFocus
                          onChange={(e) => {
                            setRut(e.target.value)
                            // caca
                          }}
                          // @ts-ignore
                          ref={_refOBS}
                          disabled={identityEvents.called}
                        />
                      </Form.Item>}

                      {identityEvents.called && (
                        <>
                          <Form.Item
                            name={['patient', 'names']}
                            label='Nombres'
                            required
                            rules={[
                              {
                                required: true,
                                message: 'Ingrese el nombre del paciente'
                              }
                            ]}
                          >
                            <Input disabled={matchRut} />
                          </Form.Item>

                          <Form.Item
                            name={['patient', 'socialName']}
                            label='Nombre Social'
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name={['patient', 'lastName']}
                            label='Apellido Paterno'
                            required
                            rules={[
                              {
                                required: true,
                                message:
                                  'Ingrese el apellido paterno del paciente'
                              }
                            ]}
                          >
                            <Input disabled={matchRut} />
                          </Form.Item>

                          <Form.Item
                            name={['patient', 'surName']}
                            label='Apellido Materno'
                            // required
                            // rules={[
                            //   {
                            //     required: true,
                            //     message:
                            //       'Ingrese el apellido materno del paciente'
                            //   }
                            // ]}
                          >
                            <Input disabled={matchRut} />
                          </Form.Item>

                          {!nnRUT && <Form.Item
                            name={['patient', 'birthday']}
                            label='Fecha de Nacimiento'
                          >
                            <Input type='date' />
                          </Form.Item>}

                          <Form.Item name={['patient', 'sexId']} label='Sexo'>
                            <select>
                              <option value='2'>Femenino</option>
                              <option value='1'>Masculino</option>
                              <option value='3'>Prefiere no decirlo</option>
                            </select>
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                </Card>
                <>
                  {started &&
                    identityEvents.called &&
                    !patientAddEvents.called && (
                      <Card title='Información en el Hospital'>
                        <Form.Item
                          name={['patient', 'inconexLocId']}
                          label='Ubicación'
                          required
                          rules={[
                            {
                              required: true,
                              message: 'Debe seleccionar una ubicación'
                            }
                          ]}
                        >
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder='ej: Pieza L2S1'
                            optionLabelProp='label'
                            autoClearSearchValue
                            optionFilterProp='children'
                            filterOption={(input, option) => {
                              // @ts-ignore
                              const l: string = option?.label.toLowerCase()
                              return (
                                l.indexOf(input.toLowerCase()) >= 0
                              )
                            }}
                          >
                            {locations.map((loc) => (
                              <Option value={loc.id} label={loc.code}>
                                <div className='demo-option-label-item'>
                                  {loc.name} - {loc.code}<br />
                                  <small>{loc.breadcrumb}</small>
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name={['patient', 'ingressDate']}
                          label='Fecha de ingreso'
                          required
                        >
                          <Input type='datetime-local' />
                        </Form.Item>

                        <Form.Item
                          name={['patient', 'obs']}
                          label='Observaciones'
                          // valuePropName='getEditor'
                        >
                          {/* <Input.TextArea placeholder='Información adicional del paciente...' /> */}
                          <BraftEditor
                            value={editorState}
                            language='en'
                            onChange={setEditorState}
                            controls={[
                              'bold',
                              'italic',
                              'underline',
                              'separator',
                              'undo',
                              'redo',
                              'separator',
                              'text-color'
                            ]}
                            // onSave={this.submitContent}
                          />
                        </Form.Item>
                      </Card>
                    )}
                </>
              </>
            )}

            {/* Guardian Form */}
            {step === 1 && (
              <>
                <Card
                  loading={
                    loading ||
                    identityEvents.loading ||
                    patientAddEvents.loading ||
                    guardianAddEvents.loading
                  }
                  title='Información del Tutor'
                  className='TutorHead'
                >
                  <p>
                    Completa la información del tutor del paciente{' '}
                    <strong>
                      {PatientInstance?.Person?.firstName}{' '}
                      {PatientInstance?.Person?.lastName}{' '}
                      {PatientInstance?.Person?.surName}
                    </strong>
                    .
                  </p>

                  {!startedGuardian && <><small>
                    <InfoCircleTwoTone twoToneColor={'orange'} /> Opción recomendada según el dispositivo utilizado.
                  </small><br /><br /></>}

                  {!startedGuardian && (
                    <div className="horizontalButtons">
                      <Button
                        onClick={() => {
                          setModalQR(true)
                        }}
                        block
                        // type='primary'
                        size='large'
                        icon={<Badge color={isMobile ? 'orange' : undefined} dot count={isMobile ? 1 : 0}><QrcodeOutlined /></Badge>}
                      >
                        Ingreso con Cédula de Identidad
                      </Button>
                      <Button
                        onClick={() => {
                          setStartedGuardian(true)
                        }}
                        block
                        size='large'
                        icon={<Badge color={!isMobile ? 'orange' : undefined} dot count={!isMobile ? 1 : 0}><FontSizeOutlined /></Badge>}
                      >
                        Ingreso manual del RUT
                      </Button>
                    </div>
                  )}

                  {startedGuardian && (
                    <>
                      <Form.Item
                        name={['guardian', 'RUT']}
                        label='RUT'
                        required
                        rules={[
                          {
                            required: true,
                            message: 'Ingrese el RUT del paciente'
                          }
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            setRutGuardian(e.target.value)
                          }}
                          disabled={identityEvents.called}
                        />
                      </Form.Item>

                      {identityEvents.called && (
                        <>
                          <Form.Item
                            name={['guardian', 'names']}
                            label='Nombres'
                            required
                            rules={[
                              {
                                required: true,
                                message: 'Ingrese el nombre del paciente'
                              }
                            ]}
                          >
                            <Input disabled={matchRutGuardian} />
                          </Form.Item>

                          <Form.Item
                            name={['guardian', 'socialName']}
                            label='Nombre Social'
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name={['guardian', 'lastName']}
                            label='Apellido Paterno'
                            required
                            rules={[
                              {
                                required: true,
                                message:
                                  'Ingrese el apellido paterno del paciente'
                              }
                            ]}
                          >
                            <Input disabled={matchRutGuardian} />
                          </Form.Item>

                          <Form.Item
                            name={['guardian', 'surName']}
                            label='Apellido Materno'
                            required
                            rules={[
                              {
                                required: true,
                                message:
                                  'Ingrese el apellido materno del paciente'
                              }
                            ]}
                          >
                            <Input disabled={matchRutGuardian} />
                          </Form.Item>

                          <Form.Item
                            name={['guardian', 'birthday']}
                            label='Fecha de Nacimiento'
                            key='guardianBirthday'
                          >
                            <Input type='date' />
                          </Form.Item>

                          <Form.Item name={['guardian', 'sexId']} label='Sexo'>
                            <Select>
                              <Select.Option value='2'>Femenino</Select.Option>
                              <Select.Option value='1'>Masculino</Select.Option>
                              <Select.Option value='3'>
                                Prefiere no decirlo
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                </Card>
                {startedGuardian &&
                  identityEvents.called &&
                  !guardianAddEvents.loading && (
                    <Card
                      className='TutorHead'
                      title={`Relación con ${PatientInstance?.Person?.firstName} ${PatientInstance?.Person?.lastName} ${PatientInstance?.Person?.surName}`}
                    >
                      <Form.Item
                        name={['guardian', 'relationshipId']}
                        label='Parentesco'
                        required
                        rules={[
                          {
                            required: true,
                            message:
                              'Debe seleccionar una relación con el paciente'
                          }
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder='ej: Padre'
                          optionLabelProp='label'
                          autoClearSearchValue
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            console.log({ input, option })
                            return (
                              option?.children.props.children
                                .join('')
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            )
                          }}
                        >
                          {[
                            {
                              id: 1,
                              name: 'Padre'
                            },
                            {
                              id: 2,
                              name: 'Madre'
                            },
                            {
                              id: 11,
                              name: 'Hijo'
                            },
                            {
                              id: 12,
                              name: 'Hija'
                            },
                            {
                              id: 3,
                              name: 'Hermano'
                            },
                            {
                              id: 4,
                              name: 'Hermana'
                            },
                            {
                              id: 5,
                              name: 'Tío'
                            },
                            {
                              id: 6,
                              name: 'Tía'
                            },
                            {
                              id: 7,
                              name: 'Abuelo'
                            },
                            {
                              id: 8,
                              name: 'Abuela'
                            },
                            {
                              id: 9,
                              name: 'Conyuge'
                            },
                            {
                              id: 10,
                              name: 'Otro'
                            }
                          ].map((item) => (
                            <Select.Option
                              key={item.id}
                              value={item.id}
                              label={item.name}
                            >
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name={['patient', 'obs']}
                        label='Observaciones'
                        key={'ObsPatient'}
                      >
                        <Input.TextArea placeholder='Información adicional del tutor...' />
                      </Form.Item>
                    </Card>
                  )}
              </>
            )}

            {/* Contact Form */}
            {step === 2 && (
              <>
                <Card
                  loading={
                    loading ||
                    identityEvents.loading ||
                    patientAddEvents.loading ||
                    guardianAddEvents.loading
                  }
                  title='Información de Contacto'
                  className='ContactHead'
                >
                  <p>
                    Completa la información de contacto al tutor{' '}
                    <strong>
                      {GuardianInstance?.firstName} {GuardianInstance?.lastName}{' '}
                      {PatientInstance?.surName}
                    </strong>
                    .
                  </p>

                  <Form.Item
                    name={['contact', 'phoneId']}
                    label='Teléfono de Contacto'
                    rules={[
                      {
                        pattern: new RegExp('[0-9]{9}'),
                        min: 9,
                        max: 9
                      }
                    ]}
                  >
                    <Input
                      type='tel'
                      key='contactTelephone'
                      pattern='[0-9]{9}'
                    />
                  </Form.Item>

                  <Form.Item
                    name={['contact', 'isWhatsApp']}
                    label='¿El número es WhatsApp?'
                    valuePropName='checked'
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    name={['contact', 'mail']}
                    label='Mail de Contacto'
                    rules={[
                      {
                        type: 'email',
                        message: 'El mail ingresado no es válido'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Card>
              </>
            )}

            {/* Form buttons  */}
            {!(
              loading ||
              identityEvents.loading ||
              patientAddEvents.loading ||
              guardianAddEvents.loading
            ) && (
              <div className='FooterButtons'>
                {((step === 0 && started) ||
                  (step === 1 && startedGuardian) ||
                  step === 2) && (
                  <Button
                    disabled={!isValid && !nnRUT}
                    loading={validating && !identityEvents.called}
                    block
                    size='large'
                    type='primary'
                    className='Button__Next'
                    onClick={() => {
                      _next()
                    }}
                  >
                    Continuar
                  </Button>
                )}
                {step === 0 && !identityEvents.called && (
                  <Button
                    block
                    size='large'
                    type='ghost'
                    icon={<QuestionOutlined />}
                    danger
                    className='Button__WithoutRUT'
                    onClick={() => {
                      Modal.confirm({
                        title: 'Paciente sin identificación',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Puedes registrar pacientes sin identificación, para que puedan ser registrados por el sistema. ¿Estás seguro que deseas continuar sin RUT?. Posteriormente podrás asociarlo a un RUT.',
                        okText: 'Continuar',
                        cancelText: 'Cancelar',
                        okButtonProps: {
                          danger: true
                        },
                        onOk: () => {
                          // @ts-ignore
                          identityEvents.called = true
                          setStarted(true)
                          
                          setNNRut(true)
                          // form.submit()
                        }
                      })
                    }}
                  >
                    Paciente sin Identificación
                  </Button>
                )}

                {step === 1 && (
                  <Button
                    block
                    size='large'
                    type='ghost'
                    icon={<QuestionOutlined />}
                    danger
                    className='Button__WithoutRUT'
                    onClick={() => {
                      Modal.confirm({
                        title: 'Registro de Paciente',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Puedes registrar el tutor posteriormente a partir de un Registro de Actividad: "Registro de Familiar".',
                        okText: 'Continuar',
                        cancelText: 'Cancelar',
                        okButtonProps: {
                          danger: true
                        },
                        onOk: () => {
                          setMainLoading(true)
                          // Enable navigation
                          dispatch(setNavigation(false))
                          setTimeout(() => {
                            history.push(`/patient/view/${PatientInstance?.id}`)
                          }, 300)
                        }
                      })
                    }}
                  >
                    Continuar sin Tutor
                  </Button>
                )}
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  )
}

export default RegisterPatient
