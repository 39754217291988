import React, { useEffect, useState } from 'react'

import { UserOutlined, LogoutOutlined, PhoneOutlined, FilePdfOutlined, KeyOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Collapse, PageHeader } from 'antd'

// Store
import { useDispatch, useSelector } from 'react-redux'

import './Configuration.scss'
import { useTranslation } from 'react-i18next'
import { logout } from '../../Store/Actions'
import { useHistory } from 'react-router-dom'

const { Panel } = Collapse

type SelectorConfigurationProps = {
  userInfo: any
  permissions: string[]
}

type DetailPermissions = {
  title: string
  _permissions: string[]
}

function fromEntries (iterable: any) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val
    return obj
  }, {})
}

function Configuration({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const { userInfo, permissions } = useSelector(
    (state: SelectorConfigurationProps) => state
  )
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [firstName = '  ', lastName = '  '] = userInfo.Profile.firstName.split(' ')
  const [iniF] = firstName
  const [iniL] = lastName

  const [detailPermissions, setDetailPermissions] =
    useState<DetailPermissions[]>()

  useEffect(() => {
    setMainLoading(false)

    // Category permissions on groups
    const groups = new Map<string, string[]>()
    for (const permission of permissions) {
      const [group, value] = permission.split(':')
      if (!groups.has(group)) {
        groups.set(group, [value])
      } else {
        groups.set(group, [...groups.get(group)!, value])
      }
    }

    const _detail = []
    for (const [groupValue, _permissions] of Object.entries(
      fromEntries(groups)
    )) {
      _detail.push({
        title: groupValue,
        _permissions
      })
    }

    // @ts-ignore
    setDetailPermissions(_detail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!exposeSubMenu) {
      // const last = { key: 'permissions', name: 'Permisos', icon: <KeyOutlined /> }
      const last = { key: 'index', name: 'Mi perfil', icon: <UserOutlined /> }
      const option = isMobile
        ? [
            last,
            {
              key: 'user:logout',
              name: 'Cerrar Sesión',
              icon: <LogoutOutlined />
            }
          ]
        : [last]
      exposeSubMenu([
        /*{ key: 'usersgroups', name: 'Usuarios y Grupos', icon: <UsergroupAddOutlined /> },*/
        ...option
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exposeSubMenu])

  return (
    <div className='App'>
      <div className='Configuration'>
        <div className='Wrapper'>
          <Avatar
            style={{
              float: 'left',
              marginRight: '32px',
              backgroundColor: '#253040',
              verticalAlign: 'middle'
            }}
            size={64}
          >
            {iniF}
            {iniL}
          </Avatar>
          <PageHeader
            className='ProfileHeader'
            title={`${userInfo.Profile.firstName} ${userInfo.Profile.lastName}`}
            subTitle={userInfo.GroupInfo.name}
          />

          <Card
            // size='small'
            title='Permisos'
            // extra={<a href='#'>More</a>}
            style={{ width: '100%', marginTop: 24 }}
          >
            <p>
              Revisa los permisos que tienes activos en la aplicación, haz clic
              en alguno de los títulos para saber más.
            </p>

            <Collapse>
              {detailPermissions?.map((_dP) => (
                <Panel header={t(`title${_dP.title}`)} key={_dP.title}>
                  <ul>
                    {_dP._permissions.map((_p) => {
                      const p = `${_dP.title}${_p}`
                      console.log({ p })
                      return <li key={p}>{t(p)}</li>
                    })}
                  </ul>
                </Panel>
              ))}
            </Collapse>
          </Card>

          <div className='ProfileButtons'>
            <div
              className='ProfileButton__BigButton'
              onClick={() => {
                window.open('https://wa.me/56939333140', '_new')
              }}
            >
              <Button
                // type='primary'
                shape='circle'
                icon={
                  <PhoneOutlined
                    style={{
                      fontSize: 32
                    }}
                  />
                }
                size='large'
                className='ProfileButton__BigButton'
              />
              ¡Necesito ayuda!
            </div>


            <div
              className='ProfileButton__BigButton'
              onClick={() => {
                window.open(`https://induccion.oirscontigo.cl`)
              }}
            >
              <Button
                // type='primary'
                shape='circle'
                icon={
                  <FilePdfOutlined
                    style={{
                      fontSize: 32
                    }}
                  />
                }
                size='large'
                className='ProfileButton__BigButton'
              />
              Manual de Uso
            </div>
 
            <div
              className='ProfileButton__BigButton'
              onClick={() => {
                history.push('/configuration/password')
              }}
            >
              <Button
                // type='primary'
                shape='circle'
                icon={
                  <KeyOutlined
                    style={{
                      fontSize: 32
                    }}
                  />
                }
                size='large'
                className='ProfileButton__BigButton'
              />
              Cambiar Contraseña
            </div>


            <div
              className='ProfileButton__BigButton'
              onClick={() => {
                // Logout
                dispatch(logout(null))
                setTimeout(() => {
                  window.location.reload()
                }, 300)
              }}
            >
              <Button
                // type='primary'
                shape='circle'
                icon={
                  <LogoutOutlined
                    style={{
                      fontSize: 32
                    }}
                  />
                }
                size='large'
                className='ProfileButton__BigButton'
              />
              Cerrar Sesión
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Configuration