import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import * as firebase from 'firebase'

// firebase.initializeApp({
//   apiKey: "AIzaSyAcLK2ToFTznH3aSXnFH7BPWPAY09MXzBU",
//   authDomain: "boldm-f1e53.firebaseapp.com",
//   databaseURL: "https://boldm-f1e53.firebaseio.com",
//   projectId: "boldm-f1e53",
//   storageBucket: "boldm-f1e53.appspot.com",
//   messagingSenderId: "298375991803",
//   appId: "1:298375991803:web:2d0e3a465506adf6e2bb5e",
//   measurementId: "G-YMTHG8GQHX"
// });
// firebase.analytics();

ReactDOM.render(
  // <React.>
    <App />,
  // </React.>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
