import React, { useEffect, useState } from 'react'

import { UserOutlined, LogoutOutlined } from '@ant-design/icons'
import { Card, Collapse } from 'antd'

// Store
import { useSelector } from 'react-redux'

import './PasswordUpdate.scss'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

type PasswordUpdateProps = {
  userInfo: any
  permissions: string[]
}

type DetailPermissions = {
  title: string
  _permissions: string[]
}

function fromEntries (iterable: any) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val
    return obj
  }, {})
}

function PasswordUpdate({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const { permissions } = useSelector(
    (state: PasswordUpdateProps) => state
  )
  const { t } = useTranslation()
  // const dispatch = useDispatch()

  // const [firstName = '  ', lastName = '  '] = userInfo.Profile.firstName.split(' ')
  // const [iniF] = firstName
  // const [iniL] = lastName

  const [detailPermissions, setDetailPermissions] =
    useState<DetailPermissions[]>()

  useEffect(() => {
    setMainLoading(false)

    // Category permissions on groups
    const groups = new Map<string, string[]>()
    for (const permission of permissions) {
      const [group, value] = permission.split(':')
      if (!groups.has(group)) {
        groups.set(group, [value])
      } else {
        groups.set(group, [...groups.get(group)!, value])
      }
    }

    const _detail = []
    for (const [groupValue, _permissions] of Object.entries(
      fromEntries(groups)
    )) {
      _detail.push({
        title: groupValue,
        _permissions
      })
    }

    // @ts-ignore
    setDetailPermissions(_detail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!exposeSubMenu) {
      // const last = { key: 'permissions', name: 'Permisos', icon: <KeyOutlined /> }
      const last = { key: 'index', name: 'Mi perfil', icon: <UserOutlined /> }
      const option = isMobile
        ? [
            last,
            {
              key: 'user:logout',
              name: 'Cerrar Sesión',
              icon: <LogoutOutlined />
            }
          ]
        : [last]
      exposeSubMenu([
        /*{ key: 'usersgroups', name: 'Usuarios y Grupos', icon: <UsergroupAddOutlined /> },*/
        ...option
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exposeSubMenu])

  return (
    <div className='App'>
      <div className='PasswordUpdate'>
        <div className='Wrapper'>
          <p>Puedes cambiar tu contraseña de acceso a <strong>OIRS Contigo</strong> desde la opción inferior, recuerda que al hacer este cambio, deberás iniciar sesión nuevamente en tus otros dispositivos.</p>

          <Card
            // size='small'
            title='Actualización de Contraseña'
            // extra={<a href='#'>More</a>}
            style={{ width: '100%', marginTop: 24 }}
          >
            <Collapse>
              {detailPermissions?.map((_dP) => (
                <Panel header={t(`title${_dP.title}`)} key={_dP.title}>
                  <ul>
                    {_dP._permissions.map((_p) => {
                      const p = `${_dP.title}${_p}`
                      console.log({ p })
                      return <li key={p}>{t(p)}</li>
                    })}
                  </ul>
                </Panel>
              ))}
            </Collapse>
          </Card>

        
        </div>
      </div>
    </div>
  )
}

export default PasswordUpdate