import { gql } from '@apollo/client'

export const PATIENT_ARCHIVE = gql`
  query PatientList($PatientListInput: PatientListInput!) {
    PatientList(input: $PatientListInput) {
      success
      state
      pages
      total
      finalLocations {
        id
        code
      }
      Patients {
        id
        comments
        statusId
        statusImage
        Person {
          id
          firstName
          lastName
          surName
          socialName
        }
        PatientLocation {
          ingressDate
          releaseDate
          DefReleaseEvent {
            id
            name
          }
          Location {
            id
            code
            name
          }
        }
        PatientGuardian {
          Guardian {
            firstName
            lastName
            surName
            socialName
            Contact {
              id
              phone
              isWhatsApp
              mail
            }
          }
        }
      }
    }
  }
`
