import React, { useEffect, useState } from 'react'

import { Button, Card, Col, Empty, List, message, Modal, Row } from 'antd'
import prompt from 'antd-prompt'

import { RetweetOutlined, ExclamationCircleOutlined, DownloadOutlined } from '@ant-design/icons'

// Store
import { useSelector } from 'react-redux'
import { format as formatDV } from 'react-rut'
import { useMutation, useQuery } from '@apollo/client'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import sanitizeHtml from 'sanitize-html'

import './LocationOcupation.scss'
import { PATIENT_LOCK, PATIENT_OCUPATION } from './connection'
import { useHistory, useParams } from 'react-router-dom'
import { calcDV, stringToStyleArray } from '../../Utils'
import { PATIENT_RELEASE } from '../PatientView/connection'
import { differenceInDays, format, parse } from 'date-fns'

import { es as locale } from 'date-fns/locale'

type SelectorLocationOcupationProps = {
  userInfo: any
  permissions: string[]
  locations: any[]
  token: string
}

function LocationOcupation({
  isMobile,
  exposeSubMenu,
  setMainLoading,
  setRightIcon
}: any) {
  const { token, userInfo } = useSelector(
    (state: SelectorLocationOcupationProps) => state
  )
  const history = useHistory()
  // const { state } = useLocation<any>()
  const { locationId } = useParams<any>()

  const [parentLocation, setParentLocation] = useState<any>()
  const [rooms, setRooms] = useState<any[]>()

  const [residenteAM, setResidenteAM] = useState<any>()
  const [residentePM, setResidentePM] = useState<any>()

  const e_LocationOcupation = useQuery(PATIENT_OCUPATION, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    variables: {
      PatientOcupationInput: {
        locationId: Number(locationId)
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })

  const [release, e_PatientRelease] = useMutation(PATIENT_RELEASE, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    onCompleted: (data) => {
      if (data?.PatientRelease?.success) {
        message.success('Cama desbloqueada exitosamente.', 5)
        exitBedModal()
      }
    },
    onError: (err) => {
      message.error(err.message)
      exitBedModal()
    }
  })

  const [lock, e_PatientLock] = useMutation(PATIENT_LOCK, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    onCompleted: (data) => {
      if (data?.PatientLock?.success) {
        message.success('Cama bloqueada exitosamente.', 5)
        exitBedModal()
      }
    },
    onError: (err) => {
      message.error(err.message)
      exitBedModal()
    }
  })

  useEffect(() => {
    setRightIcon({
      icon: <RetweetOutlined style={{ fontSize: 28 }} />,
      onClick: () => {
        setMainLoading(true)
        setTimeout(() => {
          e_LocationOcupation.refetch()
          setMainLoading(false)
        }, 1500)
      }
    })
    return () => {
      setRightIcon({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMainLoading(
      e_LocationOcupation.loading ||
        e_PatientRelease.loading ||
        e_PatientLock.loading
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    e_LocationOcupation.loading,
    e_PatientRelease.loading,
    e_PatientLock.loading
  ])

  useEffect(() => {
    const { data } = e_LocationOcupation
    if (data?.PatientOcupation?.success) {
      const { name, code, description, cols, sortOrder, final } =
        data.PatientOcupation

      setParentLocation({
        locationId: data.PatientOcupation.locationId,
        name,
        code,
        description,
        cols:
          cols === 0
            ? data.PatientOcupation.rooms.length > 6
              ? 6
              : data.PatientOcupation.rooms.length
            : cols,
        sortOrder,
        final
      })

      setRooms(data.PatientOcupation.rooms)

      // @ts-ignore
      const state = { ...history.location.state }
      // @ts-ignore
      state.goBackUrl = `/location/ocupation/${data.PatientOcupation.locationId}`
      // @ts-ignore
      history.replace({
        // @ts-ignore
        ...history.location,
        // @ts-ignore
        state
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_LocationOcupation.data])

  // const [bedToRelease, setBedToRelease] = useState<number>()
  // const [bedModal, setBedModal] = useState<boolean>(false)

  const unlockBed = (bedId: number, patientId = 0) => {
    Modal.confirm({
      title: '¿Está seguro de desbloquear la cama?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Desbloquear',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: () => {
        return new Promise(async (resolve, reject) => {
          const w = await release({
            variables: {
              PatientReleaseInput: {
                locationId: bedId,
                patientId
              }
            }
          })
          if (w) {
            resolve(w)
          } else {
            reject()
          }
        })
      },

      onCancel: () => {
        exitBedModal()
      },
      maskClosable: true
    })
  }

  const exitBedModal = () => {
    e_LocationOcupation.refetch()
  }

  if (!e_LocationOcupation.loading && rooms?.length === 0) {
    return (
      <div className='App'>
        <div className='LocationOcupation'>
          <div className='Wrapper'>
            <Empty
              style={{
                marginTop: 24
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='App'>
      <div className='LocationOcupation'>
        <div className='Wrapper'>
          <HeaderApp
            pretitle={parentLocation?.code}
            title={parentLocation?.name}
            description={`Total de camas disponibles ${
              rooms?.filter((room) => room.final && !room.Occupant).length
            }/${rooms?.filter((room) => room.final).length}`}
            rightButton={
              rooms?.filter((room) => room.final).length && (
                <>
                  <ReactHTMLTableToExcel
                    id='btnDownload'
                    className='btnDownload'
                    key={`${parentLocation?.name}-table-xls-button`}
                    style={{
                      display: 'none'
                    }}
                    table='table-to-xls'
                    filename={`${format(new Date(), 'yyyyMMdd')} ${
                      parentLocation?.code
                    } ${parentLocation?.name}`}
                    sheet={parentLocation?.code || 'HCFB'}
                    buttonText='Exportar a Excel'
                  />
                  <Button
                    icon={<DownloadOutlined />}
                    style={{
                      marginTop: 16,
                      marginRight: 32
                    }}
                    size='large'
                    onClick={async () => {
                      await prompt({
                        title: `Información de Exportación`,
                        placeholder: 'Ingrese nombre de Residente',
                        rules: [
                          {
                            required: true,
                            message: 'Por favor ingrese Residente'
                          }
                        ],
                        modalProps: {
                          okType: 'danger'
                          // width: '80'
                        },
                        onOk: async (value) => {
                          setResidenteAM(value)
                          return new Promise(async (resolve, reject) => {
                            const w = await prompt({
                              title: `Información de Exportación: Nombre Gestor AM/PM`,
                              placeholder: 'Ingrese nombre de Gestor AM/PM',
                              value: `${userInfo?.Profile?.firstName} ${userInfo?.Profile?.lastName}`,
                              rules: [
                                {
                                  required: true,
                                  message: 'Por favor ingrese Gestor AM/PM'
                                }
                              ],
                              modalProps: {
                                okType: 'danger'
                                // width: '80'
                              },
                              onOk: (valuex) => {
                                setResidentePM(valuex)
                                setTimeout(() => {
                                  document.getElementById('btnDownload')?.click()
                                }, 100)
                                return (valuex?.length || 0) > 0
                              }
                            })
                            if (!!w) {
                              resolve(true)
                            } else {
                              reject()
                            }
                          })
                        }
                      })
                    }}
                  >
                    Exportar a Excel
                  </Button>
                </>
              )
            }
          />

          <table
            id='table-to-xls'
            style={{
              display: 'none'
            }}
          >
            <tbody>
              <tr>
                <th>&nbsp;</th>
                <th colSpan={parentLocation?.cols}>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <th colSpan={parentLocation?.cols}><h2>{parentLocation?.name}</h2></th>
                <th>&nbsp;</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td colSpan={parentLocation?.cols} align='center'>
                  <span style={{ textAlign: 'center' }}>
                    Residente: <strong>{residenteAM}</strong>
                  </span>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td colSpan={parentLocation?.cols} align='center'>
                  <span style={{ textAlign: 'center' }}>
                    Gestor AM/PM: <strong>{residentePM}</strong>
                  </span>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <th colSpan={parentLocation?.cols}>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
              {rooms
                ?.reduce((acc, room) => {
                  const cols = parentLocation?.cols || 4
                  let [group] = [...acc].reverse()
                  if (!group || group.length === cols) {
                    group = [room]
                    acc.push(group)
                  } else {
                    group.push(room)
                  }
                  return acc
                }, [])
                .map((group: any[], index: number) => {
                  return (
                    <tr key={`Group_TR${index}`}>
                      <td>&nbsp;</td>
                      {group.map((room: any) => {
                        /** Create a Table per Room */
                        const styles = stringToStyleArray(
                          room?.Occupant?.comments,
                          false
                        )
                        let textColor = '#000000'
                        console.log(styles)
                        if (styles?.length && styles.find(
                                (style: any) =>
                                  Object.keys(style)[0] === 'background-color'
                              )) {
                          // @ts-ignore
                          textColor =
                            Object.values(
                              styles.find(
                                (style: any) =>
                                  Object.keys(style)[0] === 'background-color'
                              )
                            )[0] || '#000000'
                        }
                        return (
                          <td key={`Group_TD${room.id}`}>
                            <table
                              style={{
                                border: '1px solid #000'
                              }}
                            >
                              <tbody>
                                {/* Name of Room */}
                                <tr>
                                  <th
                                    style={{
                                      border: '1px solid #000',
                                      backgroundColor: '#EA9999',
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    {`${room.description}`.replace(
                                      'Habitación ',
                                      ''
                                    )}
                                  </th>
                                </tr>
                                {/* Full name data */}
                                <tr>
                                  {room?.Occupant?.Person && (
                                    <td
                                      style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                      }}
                                    >
                                      {room?.Occupant?.Person?.firstName?.toUpperCase()}{' '}
                                      {room?.Occupant?.Person?.lastName?.toUpperCase()}{' '}
                                      {room?.Occupant?.Person?.surName?.toUpperCase() ||
                                        ''}
                                    </td>
                                  )}

                                  {room?.Occupant &&
                                    !room?.Occupant?.Person &&
                                    room?.Occupant?.isBlocked && (
                                      <td
                                        rowSpan={4}
                                        style={{
                                          textAlign: 'center',
                                          backgroundColor: '#DDD',
                                          color: '#FF0000',
                                          fontWeight: 'bold',
                                          textTransform: 'uppercase',
                                          verticalAlign: 'middle'
                                        }}
                                      >
                                        {room?.Occupant?.comments?.toUpperCase()}
                                      </td>
                                    )}

                                  {(!room?.Occupant ||
                                    !room?.Occupant?.Person) &&
                                    !room?.Occupant?.isBlocked && (
                                      <td rowSpan={4}>&nbsp;</td>
                                    )}
                                </tr>
                                {/* Background from text comments */}
                                {room?.Occupant?.Person && (
                                  <tr
                                    style={{
                                      textAlign: 'center',
                                      textTransform: 'uppercase'
                                    }}
                                  >
                                    <td
                                      rowSpan={1}
                                      colSpan={1}
                                      style={{
                                        color: textColor,
                                        fontWeight:
                                          textColor !== '#000000'
                                            ? 'bold'
                                            : 'normal',
                                        textTransform: 'uppercase'
                                      }}
                                    >
                                      {sanitizeHtml(
                                        `${
                                          room?.Occupant?.Person?.rut
                                            ? `${`${formatDV(
                                                `${
                                                  room.Occupant.Person.rut
                                                }${calcDV(
                                                  room.Occupant.Person.rut
                                                )}`
                                              )}`}`
                                            : 'Sin RUT'
                                        } ${
                                          room?.Occupant?.comments
                                            ? `- ${room?.Occupant?.comments
                                                ?.replace(/<p[^>]*>/g, '')
                                                .replace(/<span[^>]*>/g, '')
                                                .toUpperCase()}`
                                            : ''
                                        }`,
                                        {
                                          allowedTags: []
                                        }
                                      ).slice(0, 42)}
                                    </td>
                                  </tr>
                                )}
                                {/* Fecha de Ingreso */}
                                {room?.Occupant?.Person && (
                                  <tr
                                    style={{
                                      textAlign: 'center'
                                    }}
                                  >
                                    <td> {room?.Occupant?.ingressDate} </td>
                                  </tr>
                                )}
                                {/* Días de Hospitalizado */}
                                {room?.Occupant?.Person && (
                                  <tr
                                    style={{
                                      textAlign: 'center'
                                    }}
                                  >
                                    <td>
                                      {' '}
                                      {differenceInDays(
                                        new Date(),
                                        parse(
                                          room?.Occupant?.ingressDate,
                                          'dd-MM-yyyy',
                                          new Date(),
                                          { locale }
                                        )
                                      )}{' '}
                                      día
                                      {differenceInDays(
                                        new Date(),
                                        parse(
                                          room?.Occupant?.ingressDate,
                                          'dd-MM-yyyy',
                                          new Date(),
                                          { locale }
                                        )
                                      ) > 1
                                        ? 's'
                                        : ''}
                                    </td>
                                    {console.log(
                                      new Date(room?.Occupant?.ingressDate)
                                    )}
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </td>
                        )
                      })}
                      <td>&nbsp;</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>

          <Row
            gutter={[16, 16]}
            style={{
              marginLeft: 16,
              marginRight: 16
            }}
          >
            {rooms?.map((room, roomIndex) => {
              const isOccuped = !!room.Occupant && !room.Occupant.isBlocked
              const isBlocked =
                room.final && !!room.Occupant && room.Occupant.isBlocked
              const isTemporaryBlocked =
                isOccuped && room.Occupant.isTemporaryBlocked
              // const isFree = !room.Occupant

              const data = []

              if (isOccuped) {
                data.push(
                  `${room.Occupant.Person.firstName}${
                    room.Occupant?.Person?.socialName
                      ? ` (${room.Occupant?.Person?.socialName}) `
                      : ' '
                  }${room.Occupant.Person.lastName} ${
                    room.Occupant.Person.surName || ''
                  }`
                )
                data.push(
                  room.Occupant.Person.rut
                    ? `${formatDV(
                        `${room.Occupant.Person.rut}${calcDV(
                          room.Occupant.Person.rut
                        )}`
                      )}`
                    : 'Sin RUT'
                )
                data.push(
                  <>
                    {isTemporaryBlocked ? (
                      <strong>
                        Temporalmente: <br />
                      </strong>
                    ) : (
                      <></>
                    )}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: room.Occupant.comments ?? '&nbsp;'
                      }}
                    />
                  </>
                )
                data.push(`${room?.Occupant?.ingressDate}`)

                data.push(
                  <Button
                    block
                    type='primary'
                    color='#0069B8'
                    onClick={() =>
                      history.push(`/patient/view/${room.Occupant.id}`, {
                        from: 'locationOcupation',
                        goBackUrl: `/location/ocupation/${locationId}`
                      })
                    }
                    style={{
                      backgroundColor: '#0069B8',
                      borderColor: '#0069B850'
                    }}
                  >
                    Ver ficha
                  </Button>
                )
                if (isTemporaryBlocked) {
                  data.push(
                    <Button
                      block
                      type='primary'
                      color='#FF9A22'
                      onClick={() => {
                        unlockBed(room.id, room.Occupant.id * -1)
                      }}
                      style={{
                        backgroundColor: '#4caf50',
                        borderColor: '#4caf5050'
                      }}
                    >
                      Desbloquear
                    </Button>
                  )
                }
              } else if (isBlocked) {
                data.push(room.Occupant.comments)
                // @TODO Permisos
                data.push(
                  <Button
                    block
                    type='primary'
                    color='#FF9A22'
                    onClick={() => {
                      unlockBed(room.id)
                    }}
                    style={{
                      backgroundColor: '#4caf50',
                      borderColor: '#4caf5050'
                    }}
                  >
                    Desbloquear
                  </Button>
                )
              } else {
                if (room.final) {
                  data.push('-')
                  data.push('-')
                  data.push(
                    <Button
                      block
                      type='primary'
                      color='#FF9A22'
                      onClick={() => {
                        history.push('/patient/register', {
                          from: 'locationOcupation',
                          locationId: room.id,
                          returningUrl: `/location/ocupation/${locationId}`
                        })
                      }}
                      style={{
                        backgroundColor: '#FF9A22',
                        borderColor: '#FF9A2250'
                      }}
                    >
                      Registrar
                    </Button>
                  )
                  data.push(
                    <Button
                      block
                      type='primary'
                      color='#FF9A22'
                      onClick={async () => {
                        try {
                          await prompt({
                            title: `Bloqueo de cama ${room.name}`,
                            placeholder: 'Ingrese una descripción del bloqueo',
                            rules: [
                              {
                                required: true,
                                message: 'Por favor ingrese una descripción'
                              }
                            ],
                            modalProps: {
                              okType: 'danger'
                              // width: '80'
                            },
                            onOk: async (value) => {
                              return new Promise(async (resolve, reject) => {
                                const w = lock({
                                  variables: {
                                    PatientLockInput: {
                                      locationId: room.id,
                                      comments: value
                                    }
                                  }
                                })
                                if (!!w) {
                                  resolve(true)
                                } else {
                                  reject()
                                }
                              })
                            }
                          })
                        } catch (err) {
                          message.error(
                            'Por favor, ingresa un texto para el bloqueo de la cama.'
                          )
                        }
                        // history.push('/patient/register', {
                        //     from: 'locationOcupation',
                        //     locationId: room.id,
                        //     returningUrl: `/location/ocupation/${locationId}`
                        //   })
                      }}
                      style={{
                        backgroundColor: '#f44336',
                        borderColor: '#f4433650'
                      }}
                    >
                      Bloquear
                    </Button>
                  )
                } else {
                  data.push(
                    <Button
                      block
                      type='primary'
                      color='#0069B8'
                      onClick={() => {
                        history.push(`/location/ocupation/${room.id}`)
                      }}
                      style={{
                        backgroundColor: '#0069B8',
                        borderColor: '#0069B850'
                      }}
                    >
                      Entrar
                    </Button>
                  )
                }
              }

              return (
                <Col
                  md={Math.ceil(24 / parentLocation?.cols)}
                  xs={24}
                  key={`ColLocOcc_${room.id}_${isOccuped}_${isBlocked}_${isTemporaryBlocked}`}
                >
                  <Card
                    size='small'
                    title={
                      <>
                        <strong>
                          {`${room.description}`.replace('Habitación', 'Cama')}
                        </strong>
                        <br />
                        <small>{room.name}</small>
                      </>
                    }
                    style={{}}
                    headStyle={{
                      backgroundColor: room.final ? '#FFC278' : '#0069B8',
                      color: '#FFFFFF',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                      minHeight: 48,
                      lineHeight: '16px',
                      paddingTop: 4
                    }}
                    bodyStyle={{
                      backgroundColor: '#FF9A2211'
                    }}
                    // style={{ width: 300 }}
                  >
                    <List
                      size='small'
                      // footer={getFooter()}
                      // bordered
                      dataSource={data}
                      renderItem={(item, index) => (
                        <List.Item
                          key={`LocOccListItem${index}`}
                          style={{
                            flexDirection: 'column',
                            textAlign: 'left',
                            alignItems: 'flex-start'
                          }}
                        >
                          {item}
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </div>
  )
}

const HeaderApp = ({ pretitle, title, description, rightButton }: any) => {
  return (
    <div
      style={{
        paddingLeft: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <p
          style={{
            color: '#333',
            fontWeight: 300,
            fontSize: 16,
            // fontVariant: 'small-caps',
            textTransform: 'uppercase',
            marginBottom: 0,
            marginTop: 16
          }}
        >
          {pretitle}
        </p>
        <p
          style={{
            color: '#000',
            fontWeight: 300,
            fontSize: 24,
            fontVariant: 'small-caps',
            textTransform: 'lowercase',
            marginBottom: 0,
            marginTop: -8
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: '#000',
            fontSize: 15,
            opacity: 0.4,
            fontWeight: 400,
            fontVariant: 'small-caps',
            textTransform: 'lowercase'
          }}
        >
          {description}
        </p>
      </div>
      {rightButton !== 0 ? rightButton : null}
    </div>
  )
}

export default LocationOcupation
