import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useMobile } from '../Utils'

import axios from 'axios'

import { connect } from 'react-redux'

const mapStateToProps = ({ isLogged }: any) => {
    return { isLogged }
}

const PublicRoute = ({component: Component, restricted, isLogged, ...rest}: any) => {
    const isMobile = useMobile()

    useEffect(() => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        return () => {
            source.cancel()
        }
    }, [])
    return (
        <Route {...rest} render={(props: any) => {
            const stateProps = {
                isLogged,
                isMobile
            }
            return (isLogged && restricted ?
                <Redirect to="/dashboard" />
            : <Component {...props} {...stateProps}  />
        )}} />
    )
}

export default connect(mapStateToProps)(PublicRoute)