import React, { useEffect, useState } from 'react'

import {
  Row,
  Col,
  Card,
  Empty,
  Modal,
  Button,
  Input,
  Divider,
  message
} from 'antd'

import {
  UserSwitchOutlined,
  IdcardFilled,
  DatabaseFilled,
  PlusCircleFilled,
  CopyFilled,
  HistoryOutlined
} from '@ant-design/icons'

import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import 'moment/locale/es'

import './Dashboard.scss'
import { SquareIcon } from '../../Components/SquareIcon'
import { useLazyQuery, useQuery } from '@apollo/client'
import { SCAN_QR, USER_ME } from './connection'
import { updateProfile } from '../../Store/Actions'
import { useHistory } from 'react-router-dom'
import { useRut } from 'react-rut'
import QrReader from 'react-qr-reader'

moment.locale('es', {
  week: {
    dow: 1
  }
})

function Dashboard({ isMobile, exposeSubMenu, setMainLoading }: any) {
  const {
    userInfo: { Profile, GroupInfo },
    permissions,
    token,
    quickAccess
  } = useSelector((state: any) => state)

  const [modalPatient, setModalPatient] = useState(false)
  const [QR, setQR] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const checkPermission = (key: string, specify = false) => {
    const joined: string = permissions.join(';')
    const suff = !specify ? `:` : ''
    return joined.indexOf(`${key}${suff}`) > -1
  }

  // TODO: error
  const { data, loading } = useQuery(USER_ME, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })

  const [QRData, setQRData] = useState()
  const [validate, e_ValidateQR] = useLazyQuery(SCAN_QR, {
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      message.error(error.message)
      setQR(false)
    }
  })

  useEffect(() => {
    if (e_ValidateQR.data?.PatientScanQR?.success) {
      history.push(e_ValidateQR.data?.PatientScanQR?.state)
      message.success('Visita autorizada correctamente.')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_ValidateQR.data])

  useEffect(() => {
    if (QRData) {
      validate({
        variables: {
          PatientScanQRInput: {
            qr: QRData
          }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QRData])

  const updateProfileLocal = async () => {
    await dispatch<Promise<any>>(
      // @ts-ignore
      updateProfile(data?.UserMe)
    )
  }

  useEffect(() => {
    if (!loading && data?.UserMe?.success) {
      updateProfileLocal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  // useEffect(() => {
  //   if (!!exposeSubMenu) {
  //     exposeSubMenu([{ key: 'index', name: 'Resumen', icon: <HomeOutlined /> }])
  //   }

  //   return () => {
  //     //
  //   }
  // }, [exposeSubMenu])

  useEffect(() => {
    setMainLoading(loading)
  }, [loading, setMainLoading])

  const ButtonAct = ({ icon, title, goKey, color, onClick }: any) => {
    return (
      <div className='ButtonAct' key={`Icon__${goKey}`}>
        <SquareIcon color={color} dark round icon={icon} onClick={onClick} />
        <div className='ButtonAct__Title' onClick={onClick}>
          {title}
        </div>
      </div>
    )
  }

  const getOptions = () => {
    const availables = []

    // Pacientes
    if (checkPermission('patient')) {
      availables.push(
        <ButtonAct
          title='Pacientes'
          color='#0069B8'
          icon={
            <UserSwitchOutlined
              style={{
                fontSize: 32
              }}
            />
          }
          onClick={() => {
            setModalPatient(true)
          }}
          goKey='patient:modal'
        />
      )
    }

    // Actividad de Visita
    if (checkPermission('activity:scan', true) && isMobile) {
      // FF9A22
      availables.push(
        <ButtonAct
          title='Escaneo de Actividad'
          color='#FF9A22'
          icon={
            <IdcardFilled
              style={{
                fontSize: 32
              }}
            />
          }
          onClick={() => {
            setQR(true)
          }}
          goKey='patient:modal'
        />
      )
    }

    // Aforo
    if (checkPermission('capacity')) {
      availables.push(
        <ButtonAct
          title='CENSO'
          color='#D31919'
          icon={
            <DatabaseFilled
              style={{
                fontSize: 32
              }}
            />
          }
          onClick={() => {
            history.push('/location/ocupation/1')
          }}
          goKey='location:ocupation'
        />
      )
    }

    // Archivo histórico
    if (checkPermission('patient:archive', true) && !isMobile) {
      availables.push(
        <ButtonAct
          title='Histórico'
          color='#03a9f4'
          icon={
            <HistoryOutlined
              style={{
                fontSize: 32
              }}
            />
          }
          onClick={() => {
            history.push('/patient/archive')
          }}
          goKey='patient:archive'
        />
      )
    }
    return availables
  }

  const options = getOptions()

  const [{ formattedValue, isValid }, setRut] = useRut()

  return (
    <div className='App'>
      <Modal
        title={'Escaneo de Pase de Visita'}
        visible={QR}
        centered
        footer={null}
        className='TitledModal'
        onCancel={() => setQR(false)}
      >
        {QR && (
          <QrReader
            delay={!QR ? 8000 : 100}
            resolution={600}
            showViewFinder={false}
            facingMode='environment'
            key={`QR${QR ? 'on' : 'off'}`}
            onError={(err) => {
              console.error(err)
            }}
            onScan={(data) => {
              console.log(data)
              if (data && !e_ValidateQR.loading) {
                // @ts-ignore
                setQRData(data)
              }
            }}
            style={{ width: '100%' }}
          />
        )}
      </Modal>

      <Modal
        title={'Gestión de Pacientes'}
        visible={modalPatient}
        centered
        footer={null}
        className='TitledModal'
        // onOk={() => setModalPatient(false)}
        onCancel={() => setModalPatient(false)}
      >
        <div className='ButtonsOptions'>
          {/* <p>Haz clic en una opción para continuar:</p> */}
          {checkPermission('patient:get', true) && (
            <>
              <Input.Search
                placeholder='Búsqueda por RUT...'
                allowClear
                enterButton='Buscar'
                value={formattedValue}
                size='large'
                onChange={(e) => setRut(e.target.value)}
                onSearch={(value) => {
                  if (!isValid) {
                    message.error('RUT inválido, intente nuevamente.')
                  } else {
                    // Redirect to Patient List
                    history.push('/patient/list', {
                      search: value
                    })
                  }
                }}
              />
              <Divider />
            </>
          )}
          {checkPermission('patient:add', true) && (
            <Button
              icon={<PlusCircleFilled />}
              onClick={() => {
                history.push('/patient/register')
              }}
              size='large'
            >
              Registro de Nuevo Paciente
            </Button>
          )}
          {checkPermission('patient:list', true) && (
            <Button
              icon={<CopyFilled />}
              size='large'
              onClick={() => {
                history.push('/patient/list')
              }}
            >
              Ver todos los registros
            </Button>
          )}
        </div>
      </Modal>
      <div className='Dashboard'>
        <div className='Wrapper'>
          <Row>
            <Col xs={24} md={24} className='RowWelcomeApp'>
              <h4>{GroupInfo.name}</h4>
              <h1>
                Hola, <strong>{Profile.firstName}</strong>
              </h1>
              <p>Elige una opción:</p>
            </Col>
            <Col xs={24} md={24} className='MainDashboard'>
              <Card>
                <Row>
                  <Col
                    xs={24}
                    md={24}
                    style={{
                      marginBottom: 8,
                      marginTop: -8
                    }}
                  >
                    <h1>Módulos</h1>
                    <h4>Navega por los módulos de OIRS Contigo.</h4>
                  </Col>
                  {options.map((item: any, index: number) => (
                    <Col
                      xs={24 / options.length}
                      md={24 / options.length}
                      key={index}
                    >
                      {item}
                    </Col>
                  ))}
                </Row>

                <Row className='QuickAccess'>
                  <Col xs={24} md={24}>
                    <h1>Acceso rápido</h1>
                    <h4>Utiliza las funciones más recurrentes.</h4>

                    {!quickAccess.length && (
                      <Empty
                        style={{
                          marginTop: 24
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
