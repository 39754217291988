import { gql } from "@apollo/client";

export const GET_IDENTITY = gql`
query HandlersGetIdentity($GetIdentityInput: GetIdentityInput!) {
  HandlersGetIdentity(input: $GetIdentityInput) {
    success
    state
    names
    lastName
    surName
    socialName
    birthDay
    sexId
  }
}
`

export const GET_LOCATIONS = gql`
query HandlersGetLocationTree($GetLocationTreeInput: GetLocationTreeInput!) {
  HandlersGetLocationTree(input: $GetLocationTreeInput) {
    success
    state
    location {
      id
      name
      code
      description
      final
      sortOrder
      clientId
      breadcrumb
    }
  }
}
`

export const PATIENT_ADD = gql`
mutation PatientAdd($PatientAddInput: PatientAddInput!) {
  PatientAdd(input: $PatientAddInput) {
    success
    state
    Patient {
      id
      personId
      comments
      Person {
        id
        rut
        birthDay
        firstName
        socialName
        lastName
        surName
        birthDay
        sexId
      }
      PatientLocation {
        id
        locationId
        Location {
          id
          name
        }
      }
    }
  }
}
`

export const PATIENT_GUARDIAN_ADD = gql`
mutation PatientGuardianAdd($PatientGuardianAddInput: PatientGuardianAddInput!) {
  PatientGuardianAdd(input: $PatientGuardianAddInput) {
    success
    state
    PatientGuardian {
      id
      patientId
      guardianPersonId
      patientGuardianRelationshipId
      comments
      Guardian {
        id
        rut
        birthDay
        firstName
        socialName
        lastName
        surName
        birthDay
        sexId
      }
    }
  }
}
`

export const UPDATE_PERSON_CONTACT = gql`
mutation UpdatePersonContact(
  $UpdatePersonContactInput: UpdatePersonContactInput!
) {
  UpdatePersonContact(input: $UpdatePersonContactInput) {
    success
    state
  }
}
`