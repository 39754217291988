import { gql } from '@apollo/client'

export const PATIENT_LOCK = gql`
  mutation PatientLock($PatientLockInput: PatientLockInput!) {
    PatientLock(input: $PatientLockInput) {
      success
      state
    }
  }
`

export const PATIENT_OCUPATION = gql`
  query PatientOcupation($PatientOcupationInput: PatientOcupationInput!) {
    PatientOcupation(input: $PatientOcupationInput) {
      success
      state
      locationId
      name
      code
      description
      cols
      sortOrder
      final
      rooms {
        id
        locationId
        name
        code
        description
        sortOrder
        final
        Occupant {
          id
          comments
          isBlocked
          isTemporaryBlocked
          ingressDate
          Person {
            firstName
            lastName
            surName
            socialName
            rut
          }
        }
      }
    }
  }
`
