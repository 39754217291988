import { gql } from '@apollo/client'

export const DO_ACTIVITY = gql`
  mutation PatientDoActivity($PatientDoActivityInput: PatientDoActivityInput!) {
    PatientDoActivity(input: $PatientDoActivityInput) {
      success
      state
      extra
      PatientActivity {
        id
        patientId
        activityId
        authorizingId
        clientId
        Answers {
          id
          patientId
          activityId
          patientActivityId
          ActivityField {
            name
            description
          }
          answer
        }
      }
    }
  }
`
