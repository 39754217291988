import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

type SvgInlineProps = {
    url: string
}

export const SvgInline = ({ url }: SvgInlineProps) => {
    const [svg, setSvg] = useState<any>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    useEffect(() => {
        fetch(url)
            .then(res => res.text())
            .then(setSvg)
            .catch(setIsErrored)
            .then(() => setIsLoaded(true))
    }, [url]);

    if (!isLoaded) {
        return <Spin />
    }

    return (
        <div 
            className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
            // @ts-ignore
            dangerouslySetInnerHTML={{ __html: svg }}
        />
    );
}
