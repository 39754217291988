import { gql } from "@apollo/client"

export const PATIENT_LIST = gql`
query PatientList($PatientListInput: PatientListInput!) {
  PatientList(input: $PatientListInput) {
    success
    state
		Patients {
			id
			comments
			statusId
			statusImage
			Person {
				id
				firstName
				lastName
				surName
				socialName
			}
			PatientLocation {
				ingressDate
				releaseDate
				DefReleaseEvent {
					id
					name
				}
				Location {
					id
					code
					name	
				}
			}
			PatientGuardian {
				Guardian {
					firstName
					lastName
					surName
					socialName
					Contact {
						id
						phone
						isWhatsApp
						mail
					}
				}
			}
		}
  }
}
`