import {
    LOGIN_USER, LOGOUT, BLOCK_NAVIGATION, MODAL_CREATE_TYPE, UPDATE_PROFILE
} from "../../Constants"

const initialState: any = {
    /** Token JWT */
    token: null,
    /** Indicador de inicio de sesión. */
    isLogged: false,
    /** Información del usuario logueado. */
    userInfo: {},
    /** Listado de permisos del usuario logueado. */
    permissions: [],
    /** Actividades disponibles para el usuario logueado. */
    activities: [],
    /** Indicador de bloqueo de navegación */
    blockNavigation: false,
    /** Identificador del paciente seleccionado. */
    currentPatient: null,
    /** Listado de ubicaciones disponibles según permisos. */
    locations: [],
    /** Indicador de mostrar el drawer de actividades. */
    drawerActivities: false,
    /** Son los motivos por los cuales se puede liberar una cama. */
    releaseEvents: [],
    /** @soon Acciones frecuentes en la App. */
    quickAccess: [],


    /** @deprecated URL del WMS  */
    serverUrl: 'https://app.bodegaenlinea.cl/api/',
    /** @deprecated Objeto de modal. */
    modal: {
        resourceType: '',
        missionCampoId: 0,
        positive: true,
        campos: {}
    },
    /** @deprecated Indicadores.  */
    insights: {
        produccion: {
            total: 0,
            um: 'ton',
            graph: [0,0,0,0],
            budget: 0
        },
        lastDay: {
            total: 0,
            um: 'kg',
            graph: [0,0,0,0],
            budget: 0
        },
        qualityScratch: {
            total: 0,
            um: '/ 10',
            graph: [0,0,0,0],
            budget: 0
        },
        sangriaSeca: {
            total: 0,
            um: 'árboles',
            graph: [0,0,0,0],
            budget: -0
        }
    }
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case LOGIN_USER:
            return updateState(state, {
                ...action.payload    
            });
        case LOGOUT:
            return updateState(initialState, {
                token: null,
                userInfo: null,
                isLogged: false
            })
        case BLOCK_NAVIGATION:
            return updateState(state, {
                blockNavigation: action.payload
            })
        case MODAL_CREATE_TYPE:
            return updateState(state, {
                modal: {
                    ...state.modal,
                    ...action.payload
                }
            })
        case UPDATE_PROFILE:
            return updateState(state, {
                ...action.payload
            })
        case 'UPDATE_PATIENT':
            return updateState(state, {
                currentPatient: action.payload
            })
        case 'SET_DRAWER_ACTIVITIES':
            return updateState(state, {
                drawerActivities: action.payload
            })

    }
    return state
}

export default rootReducer