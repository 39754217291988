import React, { useEffect, useState } from 'react'
import { SwitchTransition, CSSTransition } from "react-transition-group";

import './index.scss'

import { useHistory } from 'react-router-dom'

import { Row, Col, Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined, LockFilled, QuestionCircleOutlined } from '@ant-design/icons'

// Store
import { attemptLogin } from '../../Store/Actions'
import { useDispatch } from 'react-redux'
import { useMobile } from '../../Utils'

import iconALNUS from '../../Assets/IconALNUS.svg'
import { useTranslation } from 'react-i18next'
//import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined'
import { useMutation } from '@apollo/client';
import { USER_LOGIN } from './connection';

import { useRut } from 'react-rut'

function Login() {
    const isMobile = useMobile()
    const isDesktop = !isMobile
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const [showingLogin, setShowingLogin] = useState<boolean>(true)
    const [loadingButton, setLoadingButton] = useState<boolean>(false)

    const [form] = Form.useForm()
    const [{ formattedValue }, setRut] = useRut()

    const [login, { data, loading }] = useMutation(USER_LOGIN, {
      onError: (err) => {
        message.error(`Oops, ha ocurrido un error: ${err.message} Revisa los datos e intenta nuevamente.`);
      }
    })

    useEffect(() => {
      setLoadingButton(loading)
    }, [loading])

    useEffect(() => {
      if (!loading && data?.UserLogin) {
        onFinish()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data])

    useEffect(() => {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        username: formattedValue
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattedValue])
    

    const onFinish = async () => {
        console.log('called onFinish')
        const { status, ...rest } = await dispatch<Promise<any>>(
            // @ts-ignore
          attemptLogin(data?.UserLogin)
        )

        console.log({ status, rest })
        if (Object.entries(rest).length < 10){
          return history.push('/dashboard')
        } else {
            const { error } = rest
            const additionalOopsData = `${error === 'invalid.credentials' ? 'Las credenciales son inválidas.' : 'Los datos ingresados no son correctos.'}`
            message.error(`Oops, ha ocurrido un error: ${additionalOopsData} Revisa los datos e intenta nuevamente.`);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
      setLoadingButton(true)
      console.log('Failed:', errorInfo);
      // message.error(errorInfo)
      setLoadingButton(false)
    };

    return (
    <div className="App LoginView">
      <Row className="Header">
        <Col span={12}>
          <img src={iconALNUS} height={64} alt='Logotipo' style={{
                marginRight: 24
              }} />
        </Col>
        <Col span={12} style={{
          textAlign: 'right'
        }}>
          <Button size='large' onClick={() => {
            setLoadingButton(true)
            window.open(`https://induccion.oirscontigo.cl`)
            setTimeout(() => {
              setLoadingButton(false)
            }, 1500)
          }} icon={<QuestionCircleOutlined />}>{t('Help')}</Button>
        </Col>
      </Row>
      
      <Row className="MainContent">
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={`${showingLogin}`}
            addEndListener={(node: any, done: any) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade">
            <>
            {showingLogin && <Col span={isDesktop ? 6 : 24} offset={isDesktop ? 9 : 0} className="ColumnLogin">
                <div className="Software__Header">
                  <h1>{t('Sign In')}</h1>
                  <h2>{t('powered by ALNUS SpA', {
                    brand: 'Hospital Clínico Felix Bulnes'
                  })}</h2>
                  <p>{t('Complete your login info.')}</p>
                </div>

                <Form
                    name="basic"
                    form={form}
                    initialValues={{
                      username: formattedValue,
                      remember: true,
                    }}
                    autoComplete="off"
                    onFinish={({ username, password}) => {
                      // alert(username)
                      login({
                        variables: {
                          UserLoginInput: {
                            username: username.replace(/\./g,'').split('-')[0],
                            password,
                            trust: true
                          }
                        }
                      })
                    }}
                    onFinishFailed={onFinishFailed}
                  >

                    <input type="username" name="hidden" id="hidden" style={{
                      width: 0,
                      height: 0,
                      border: 0,
                      padding: 0
                    }} />
                    <input type="password" name="hidden" id="hidden" style={{
                      width: 0,
                      height: 0,
                      border: 0,
                      padding: 0
                    }} />


                  <Form.Item name="username" rules={[ { required: true, message: t('Enter your id'), }, ]}>
                    <Input
                      size='large'
                      role="presentation"
                      autoComplete="off"
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder={t("Your identity card number")}
                      onBlur={(e) => {
                        setRut(e.target.value)
                      }}
                      autoFocus={true} />
                  </Form.Item>

                  <Form.Item name="password" rules={[ { required: true, message: t('Enter your password'), }, ]} >
                    <Input.Password size='large' role="presentation" autoComplete="off"  prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Password")}/>
                  </Form.Item>

                  {/* <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: '8px' }}>
                    <Checkbox>{t('Authorize this device')}</Checkbox>
                  </Form.Item> */}

                  {/* <Form.Item>
                    <a className="login-form-forgot" href="">
                      {t('Lost your password?')}
                    </a>
                  </Form.Item> */}

                  <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" block size='large' htmlType="submit" loading={loadingButton} style={{ width: '100%' }} className="green">
                      {t('Login')}
                    </Button>
                    <Button type="link" size='small' style={{ width: '100%', marginTop: 8, display: 'none' }} className="green" onClick={() => {
                      setShowingLogin(showingLogin => !showingLogin)
                    }}>
                      {t('Lost your password?')}
                    </Button>
                  </Form.Item>

                  <small style={{ fontSize: '9px', textRendering: 'optimizeLegibility' }}>
                    <LockFilled /> {t('The data will be transferred through a secure SSL encrypted connection.')}
                  </small>
                </Form>
            </Col>}
            {!showingLogin && <Col span={isDesktop ? 6 : 24} offset={isDesktop ? 9 : 0} className="ColumnLogin">
                <div className="Software__Header">
                  <h1>{t('Recover your password')}</h1>
                  <h2>{t('powered by ALNUS SpA', {
                    brand: 'Hospital Clínico Felix Bulnes'
                  })}</h2>
                  <p>{t('Complete your email to request a new password')}</p>
                </div>

                <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    autoComplete="off"
                    onFinish={(values) => {
                      console.log({ values })
                    }}
                    onFinishFailed={(errorInfo) => {
                      console.log({ errorInfo })
                      message.error(errorInfo)
                    }}
                  >

                    <input type="email" name="hidden" id="hidden" style={{
                      width: 0,
                      height: 0,
                      border: 0,
                      padding: 0
                    }} />


                  <Form.Item name="email" rules={[ { required: true, message: t('Enter your email address'), }, ]} >
                    <Input size='large' role="presentation" autoComplete="off"  prefix={<UserOutlined className="site-form-item-icon" />}  placeholder={t("Email address")} autoFocus={true} type="email" />
                  </Form.Item>


                  <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" size='large' htmlType="submit" loading={loadingButton} style={{ width: '100%', display: 'none' }} className="green">
                      {t('Recover password')}
                    </Button>
                    <Button type="link" size='small' style={{ width: '100%', marginTop: 8 }} className="green" onClick={() => {
                      setShowingLogin(showingLogin => !showingLogin)
                    }}>
                      {t('Back to login')}
                    </Button>
                  </Form.Item>

                  <small style={{ fontSize: '9px', textRendering: 'optimizeLegibility' }}>
                    <LockFilled /> {t('The data will be transferred through a secure SSL encrypted connection.')}
                  </small>
                </Form>
            </Col>}</>
          </CSSTransition>
        </SwitchTransition>
        
      </Row>

      <Row className="Footer">
        <Col span={12}>{t('Copyright', { year: new Date().getFullYear() })}</Col>
        <Col span={12} style={{ textAlign: 'right' }}>
            {/* <Button target='_new' href={`https://cdn.rubberpl.us/docs/terms-and-conditions.pdf?${new Date().getTime()}`} type="link">{t('Terms')}</Button>
            <Button target='_new' href={`https://cdn.rubberpl.us/docs/privacy-policy.pdf?${new Date().getTime()}`} type="link">{t('Privacy')}</Button> */}
            <Button target='_new' href={`mailto:ayuda@oirscontigo.cl`} type="link">{t('Contact us')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default Login